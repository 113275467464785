import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      // text: alert.text,
      type: alert.type,
      timer: alert.timer,
      redirect: alert?.redirect,
      redirectUrl: alert?.redirectUrl,
      showConfirmButton: alert.showConfirmButton,
      ...(alert?.confirm === 1 ? { confirmButtonText: alert.confirmButtonText ? alert.confirmButtonText : 'Yes', showCancelButton: true, cancelButtonText: alert?.cancelButtonText ? alert?.cancelButtonText : 'No'}: {}),
      didClose: alert.action
        ? alert.action
        : () => {
            if(alert?.redirect && alert?.redirectUrl) {
              window.location.pathname = alert.redirectUrl 
            }
            console.log('Actioned');
          },
      allowOutsideClick: false
    });
};

export const sweetAlertHandlerConfirm = (alert) => {
  const MySwal = withReactContent(Swal);
  return MySwal.fire({
    title: alert.text,
    // text: alert.text,
    type: alert.type,
    timer: alert.timer,
    showConfirmButton: alert.showConfirmButton,
    ...(alert?.confirm === 1 ? { confirmButtonText: alert.confirmButtonText ? alert.confirmButtonText : 'Yes', showCancelButton: true, cancelButtonText: alert?.cancelButtonText ? alert?.cancelButtonText : 'No'}: {}),
    didClose: alert.action
      ? alert.action
      : () => {
          console.log('Actioned');
        },
    allowOutsideClick: false
  });
};
