import React, { useContext, useEffect, useRef, useState } from 'react';
import Logo from 'src/assets/images/common/logo-slo.svg';
import MainLogo from 'src/assets/images/logo_new.svg';
import ConnectingLoader from 'src/assets/images/common/connecting.svg';
import AmazonLogo from 'src/assets/images/common/amazon.svg';

import chartloader from 'src/assets/images/common/chart-loader.svg';
import Navigation from './Navigation';
import NavBar from './NavBar';
import Breadcrumb from './Breadcrumb';

import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ConfigContext } from '../../contexts/ConfigContext';
import * as actionType from '../../store/actions';

import queryString from 'query-string';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';
import Styles from 'src/components/themeStyle';
import { ThemeContext, ThemeProvider } from 'src/providers/themeProvider';
import { Minimize } from 'react-feather';
import useZoom from 'src/hooks/useZoom';
import { useCookies } from 'react-cookie';
import { differenceInDays, isDate } from 'date-fns';
import { dataEncrypt } from 'src/api/identity';
import { useAmzContext } from 'src/contexts/AmzContext';
import { useFkContext } from 'src/contexts/FkContext';
import { useBillingContext } from 'src/contexts/BillingContext';
import { useCommonContext } from 'src/contexts/CommonContext';
import useLocationTracking from 'src/hooks/useLocationTracking';
import { useMyntraContext } from 'src/contexts/MyntraContext';
import { useBlinkitContext } from 'src/contexts/BlinkitContext';

// print (auth_url) #https://hectorai.netlify.app/aws/callback?spapi_oauth_code=RHVgzUOFoBYpVOTIoEhg&state=IN&selling_partner_id=A2KMPVLFO6FIMC

const StyleTag = () => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  console.log(themeMode.theme);
  return (
    <div>
      <style>{Styles(themeMode.theme)}</style>
    </div>
  );
};

const AdminLayout = ({ children }) => {
  const zoom = useZoom();
  const locationTracking = useLocationTracking()
  const [cookies, setCookie, removeCookie] = useCookies();
  const {commonState, commonDispatch} = useCommonContext()
  const { amzDispatch } = useAmzContext()
  const { fkDispatch } = useFkContext()
  const { myntraDispatch } = useMyntraContext()
  const {blinkitDispatch} = useBlinkitContext()
  const { billingDispatch } = useBillingContext()

  const isFlipKart = window.location.href.indexOf('account-settings') > -1;
  const ParseData = JSON.parse(localStorage.getItem('User'));

  const [isAppReady, setIsAppReady] = useState(window.location.href.indexOf('amazon') > -1 || window.location.href.indexOf('flipkart') > -1 || window.location.href.indexOf('myntra') > -1 || window.location.href.indexOf('blinkit') > -1 ? 

  window.location.href.indexOf('settings') > -1 || window.location.href.indexOf('expired') > -1 ? true : false

   : true);

  const queryParams = queryString.parse(window.location.search);
  const { code, scope } = queryParams;
  const { spapi_oauth_code, state, selling_partner_id } = queryParams;
  const isAMS = code && scope ? true : false;
  const isSeller = spapi_oauth_code && state && selling_partner_id ? true : false;

  const doAMSQueryParam = useApi(services.postService);
  const doAMSProfiles = useApi(services.postService);
  const doSellerQueryparam = useApi(services.postService);
  const doSellerProfiles = useApi(services.postService);
  const doGetProfiles = useApi(services.postService);
  const doGetFlipkartProfiles = useApi(services.getService);
  const doGetMyntraProfiles = useApi(services.getService);
  const doGetBlinkitProfiles = useApi(services.getService);
  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);

  const [activeProfiles, setActiveProfiles] = useState([]);
  const [inActiveProfiles, setInActiveProfiles] = useState([]);
  const [screenLoader, setScreenLoader] = useState(false);

  const { collapseMenu, layout, subLayout, headerFixedLayout, configBlock } = configContext.state;
  const { dispatch } = configContext;
  const [isExit, setExitActive] = useState(false);
  const [exitOverlay, setexitOverlay] = useState(false);
  const urlPaths = {
    dayparting: window.location.href.indexOf('amazon-dayparting') > -1,
    chatgpt: window.location.href.indexOf('amazon-chatgpt') > -1
  };
  const pcodedContentElement = document.querySelector('.pcoded-content');
  const pcodedMainContentElement = document.querySelector('.pcoded-main-container');

  if (urlPaths && pcodedContentElement) {
      pcodedContentElement.classList.add('px-3');
  } else if (pcodedContentElement) {
      pcodedContentElement.classList.remove('px-3');
  }

  if (pcodedContentElement) {
    if (urlPaths.chatgpt) {
        pcodedContentElement.classList.add('p-0');
    } else {
        pcodedContentElement.classList.remove('p-0');
    }
  }

  if (pcodedMainContentElement) {
      if (urlPaths.chatgpt) {
          pcodedMainContentElement.classList.add('pt-0');
      } else {
          pcodedMainContentElement.classList.remove('pt-0');
      }
  }

  let history = useHistory();

  const getCurrentPlanDetails = useApi(services.getService);
  const getDateAvailableParams = useApi(services.getService);


  useOutsideClick(ref, () => {
    if (collapseMenu) {
      setTimeout(() => {
        dispatch({ type: actionType.COLLAPSE_MENU });
      }, 3000);
    }
  });

  // useEffect(() => {
  //   if (ParseData && ParseData.email) {
  //     setTimeout(() => {
  //       setIsAppReady(true);
  //     }, 1500);
  //   }
  // }, [ParseData]);

  useEffect(() => {
    // window.location.href.indexOf('account-settings') == -1 && window.location.href.indexOf('expired') == -1 && 
    if(window.location.href.indexOf('amazon') > -1) {
      doGetProfiles.request({ url: '/profiles/mdb-get-profiles', nodeAPI: 1, payload: { active: true } });
    }
  }, [])

  useEffect(() => {
    // window.location.href.indexOf('account-settings') == -1 && window.location.href.indexOf('expired') == -1 && 
    if (window.location.href.indexOf('flipkart') > -1) {
      // ${process.env.REACT_APP_FLIPKART_ACCESS_EMAIL.indexOf(ParseData.email) > -1 ? '?profile_only=' + ParseData.email.split('@')[0] : ''}
      doGetFlipkartProfiles.request({
        url: `/flipkart/clientList`,
        nodeAPI: 1
      });
    }
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf('myntra') > -1) {
      doGetMyntraProfiles.request({
        url: `/myntra/clientList`,
        nodeAPI: 1
      });
    }
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf('blinkit') > -1) {
      doGetBlinkitProfiles.request({
        url: `/blinkit/clientList`,
        nodeAPI: 1
      });
    }
  }, []);

  const [currentPlan, setCurrentPlan] = useState({});

  useEffect(() => {
    if (process.env.REACT_APP_BILLING_ENABLE == 1) {
      getCurrentPlanDetails.request({ url: '/subscription/get-current-plan-details', nodeAPI: 1 });
    }
  }, [])

  useEffect(() => {

    const { loading, data } = getDateAvailableParams
    console.log("getDateAvailableParams", data)
    if(!loading && data && data?.result && data?.result?.start_date && data?.result?.end_date && isDate(new Date(data?.result?.start_date)) && isDate(new Date(data?.result?.end_date))) {
      console.log("getParams", {...data.result})
      commonDispatch({ type: 'UPDATE_DATE_AVAILABLE', payload: {
        ...data.result
      }})
    }

  }, [getDateAvailableParams.loading])

  const [flipkartProfiles, setFlipkartProfiles] = useState([]);
  const [myntraProfiles, setMyntraProfiles] = useState([]);
  const [blinkitProfiles, setBlinkitProfiles] = useState([]);

  useEffect(() => {
    if(!doGetFlipkartProfiles.loading && !doGetMyntraProfiles.loading && !doGetBlinkitProfiles.loading && !doGetProfiles.loading && window.location.href.indexOf('account-settings') == -1 && window.location.href.indexOf('expired') == -1) {
      if (flipkartProfiles && flipkartProfiles?.length > 0 && window.location.href.indexOf('/flipkart') > -1) {
          const getFlipkartProfile = JSON.parse(localStorage.getItem('flipkartProfile'))
          if(getFlipkartProfile?.accountInfo?.name) {
            getDateAvailableParams.request({ url: `/flipkart/check-client?client=${String(getFlipkartProfile?.accountInfo?.name).toLowerCase()}`, nodeAPI: 1 });
          }
      } else if (myntraProfiles && myntraProfiles?.length > 0 && window.location.href.indexOf('/myntra') > -1) {
        const getMyntraProfile = JSON.parse(localStorage.getItem('myntraProfile'))
        if(getMyntraProfile?.accountInfo?.name) {
          getDateAvailableParams.request({ url: `/flipkart/check-client?client=${String(getMyntraProfile?.accountInfo?.name).toLowerCase()}`, nodeAPI: 1 });
        }
      } else if (blinkitProfiles && blinkitProfiles?.length > 0 && window.location.href.indexOf('/blinkit') > -1) {
        const getBlinkitProfile = JSON.parse(localStorage.getItem('blinkitProfile'))
        if(getBlinkitProfile?.accountInfo?.name) {
          getDateAvailableParams.request({ url: `/flipkart/check-client?client=${String(getBlinkitProfile?.accountInfo?.name).toLowerCase()}`, nodeAPI: 1 });
        }
      } else if(activeProfiles && activeProfiles?.length > 0) {
          const getAmazonProfile = JSON.parse(localStorage.getItem('selectedProfileId'))
          if(getAmazonProfile?.datasetId) {
            getDateAvailableParams.request({ url: `/amazon/check-dataset?datasetId=${getAmazonProfile?.datasetId}`, payload: {}, nodeAPI: 1 });
          }
      }
    }
  }, [doGetFlipkartProfiles.loading, doGetMyntraProfiles.loading, doGetProfiles.loading, flipkartProfiles, activeProfiles])

  useEffect(() => {
    const { loading, data } = getCurrentPlanDetails
    if (!loading && data && data.success == true && data.result && Object.keys(data.result).length > 0) {
      setCurrentPlan(data.result ? { ad_account_limit: 1, ...data.result } : {});
      billingDispatch({type: 'UPDATE_PLAN_INFO', payload: { ...data.result } })
      setCookie('plan_info', data.result ? dataEncrypt({ ad_account_limit: 1, ...data.result }) : {});
    }
  }, [getCurrentPlanDetails.loading])


  useEffect(() => {
    const { loading, data } = doGetFlipkartProfiles;

    if (!loading && data && data.status == true && data.data && Array.isArray(data.data) && data.data.length > 0) {
      const profileData = data.data.map((rowItem, rowIndex) => {
        return {
          ...rowItem,
          countryCode: 'IN',
          currencyCode: 'INR',
          timezone: 'Asia/Kolkata',
          profileId: rowItem?.profileId ? rowItem?.profileId : rowItem.Lable,
          accessLevel: rowItem?.accessLevel ? rowItem?.accessLevel : 'Admin',
          profileOwner: rowItem?.profileOwner ? rowItem?.profileOwner : ParseData?.email,
          accountInfo: {
            name: rowItem.Lable,
            type: 'advertising'
          }
        };
      });
      console.log('profileData', profileData);
      if (profileData && profileData?.length > 0) {
        const currentFlipkartProfile = JSON.parse(localStorage.getItem('flipkartProfile'));
        console.log('currentFlipkartProfile', currentFlipkartProfile);
        if(currentFlipkartProfile?.profileId) {
          const profileDataSelect = profileData.filter((profile_item) => (profile_item?.profileId === currentFlipkartProfile?.profileId && profile_item?.accessLevel === currentFlipkartProfile?.accessLevel))
          console.log("profileDataSelect", profileDataSelect)
          if(profileDataSelect && profileDataSelect?.length > 0) {
            localStorage.setItem('flipkartProfile', JSON.stringify(profileDataSelect[0]));
          } else {
            localStorage.setItem('flipkartProfile', JSON.stringify(profileData[0]));
          }
        } else {
          localStorage.setItem('flipkartProfile', JSON.stringify(profileData[0]));
        }
        setFlipkartProfiles(profileData);
        localStorage.setItem('flipkartProfiles', JSON.stringify(profileData));
        fkDispatch({type: "UPDATE_FLIPKART_PROFILES", payload: profileData})
        setIsAppReady(true)
      } else {
        setFlipkartProfiles([])
        localStorage.setItem('flipkartProfile', JSON.stringify({}))
        localStorage.setItem('flipkartProfiles', JSON.stringify([]));
        fkDispatch({type: "UPDATE_FLIPKART_PROFILES", payload: []})
        setIsAppReady(true)
      }
    } else if(!loading && data && data.status == true && data.data && Array.isArray(data.data) && data.data.length == 0) {
        setFlipkartProfiles([])
        localStorage.setItem('flipkartProfile', JSON.stringify({}))
        localStorage.setItem('flipkartProfiles', JSON.stringify([]));
        fkDispatch({type: "UPDATE_FLIPKART_PROFILES", payload: []})
        setIsAppReady(true)
    }
  }, [doGetFlipkartProfiles.loading]);

  useEffect(() => {
    const { loading, data } = doGetMyntraProfiles;

    if (!loading && data && data.status == true && data.data && Array.isArray(data.data) && data.data.length > 0) {
      const profileData = data.data.map((rowItem, rowIndex) => {
        return {
          ...rowItem,
          countryCode: 'IN',
          currencyCode: 'INR',
          timezone: 'Asia/Kolkata',
          profileId: rowItem?.profileId ? rowItem?.profileId : rowItem.Lable,
          accessLevel: rowItem?.accessLevel ? rowItem?.accessLevel : 'Admin',
          profileOwner: rowItem?.profileOwner ? rowItem?.profileOwner : ParseData?.email,
          accountInfo: {
            name: rowItem.Lable,
            type: 'advertising'
          }
        };
      });
      console.log('profileData', profileData);
      if (profileData && profileData?.length > 0) {
        const currentMyntraProfile = JSON.parse(localStorage.getItem('myntraProfile'));
        console.log('currentFlipkartProfile', currentMyntraProfile);
        if(currentMyntraProfile?.profileId) {
          const profileDataSelect = profileData.filter((profile_item) => (profile_item?.profileId === currentMyntraProfile?.profileId && profile_item?.accessLevel === currentMyntraProfile?.accessLevel))
          console.log("profileDataSelect", profileDataSelect)
          if(profileDataSelect && profileDataSelect?.length > 0) {
            localStorage.setItem('myntraProfile', JSON.stringify(profileDataSelect[0]));
          } else {
            localStorage.setItem('myntraProfile', JSON.stringify(profileData[0]));
          }
        } else {
          localStorage.setItem('myntraProfile', JSON.stringify(profileData[0]));
        }
        setMyntraProfiles(profileData);
        localStorage.setItem('myntraProfiles', JSON.stringify(profileData));
        myntraDispatch({type: "UPDATE_MYNTRA_PROFILES", payload: profileData})
        setIsAppReady(true)
      } else {
        setMyntraProfiles([])
        localStorage.setItem('myntraProfile', JSON.stringify({}))
        localStorage.setItem('myntraProfiles', JSON.stringify([]));
        myntraDispatch({type: "UPDATE_MYNTRA_PROFILES", payload: []})
        setIsAppReady(true)
      }
    } else if(!loading && data && data.status == true && data.data && Array.isArray(data.data) && data.data.length == 0) {
        setMyntraProfiles([])
        localStorage.setItem('myntraProfile', JSON.stringify({}))
        localStorage.setItem('myntraProfiles', JSON.stringify([]));
        myntraDispatch({type: "UPDATE_MYNTRA_PROFILES", payload: []})
        setIsAppReady(true)
    }
  }, [doGetMyntraProfiles.loading]);

  useEffect(() => {
    const { loading, data } = doGetBlinkitProfiles;

    if (!loading && data && data.status == true && data.data && Array.isArray(data.data) && data.data.length > 0) {
      const profileData = data.data.map((rowItem, rowIndex) => {
        return {
          ...rowItem,
          countryCode: 'IN',
          currencyCode: 'INR',
          timezone: 'Asia/Kolkata',
          profileId: rowItem?.profileId ? rowItem?.profileId : rowItem.Lable,
          accessLevel: rowItem?.accessLevel ? rowItem?.accessLevel : 'Admin',
          profileOwner: rowItem?.profileOwner ? rowItem?.profileOwner : ParseData?.email,
          accountInfo: {
            name: rowItem.Lable,
            type: 'advertising'
          }
        };
      });
      console.log('profileData', profileData);
      if (profileData && profileData?.length > 0) {
        const currentBlinkitProfile = JSON.parse(localStorage.getItem('blinkitProfile'));
        if(currentBlinkitProfile?.profileId) {
          const profileDataSelect = profileData.filter((profile_item) => (profile_item?.profileId === currentBlinkitProfile?.profileId && profile_item?.accessLevel === currentBlinkitProfile?.accessLevel))
          console.log("profileDataSelect", profileDataSelect)
          if(profileDataSelect && profileDataSelect?.length > 0) {
            localStorage.setItem('blinkitProfile', JSON.stringify(profileDataSelect[0]));
          } else {
            localStorage.setItem('blinkitProfile', JSON.stringify(profileData[0]));
          }
        } else {
          localStorage.setItem('blinkitProfile', JSON.stringify(profileData[0]));
        }
        setBlinkitProfiles(profileData);
        localStorage.setItem('blinkitProfiles', JSON.stringify(profileData));
        blinkitDispatch({type: "UPDATE_BLINKIT_PROFILES", payload: profileData})
        setIsAppReady(true)
      } else {
        setBlinkitProfiles([])
        localStorage.setItem('blinkitProfile', JSON.stringify({}))
        localStorage.setItem('blinkitProfiles', JSON.stringify([]));
        blinkitDispatch({type: "UPDATE_BLINKIT_PROFILES", payload: []})
        setIsAppReady(true)
      }
    } else if(!loading && data && data.status == true && data.data && Array.isArray(data.data) && data.data.length == 0) {
        setBlinkitProfiles([])
        localStorage.setItem('blinkitProfile', JSON.stringify({}))
        localStorage.setItem('blinkitProfiles', JSON.stringify([]));
        blinkitDispatch({type: "UPDATE_BLINKIT_PROFILES", payload: []})
        setIsAppReady(true)
    }
  }, [doGetBlinkitProfiles.loading]);

  // useEffect(() => {
  //   if (!doGetProfiles.loading) {
  //     const { data, loading } = doGetProfiles;
  //     console.log('doGetProfiles----data', data);

  //     let activeData =
  //       data?.data && data?.data?.profiles
  //         ? data?.data?.profiles.filter((active) => {
  //           return active.ams.connected === true;
  //         })
  //         : [];
  //     const defaultProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
  //     if (!defaultProfile && activeData.length > 0) {
  //       window.location.reload();
  //       localStorage.setItem('selectedProfileId', JSON.stringify(activeData[0]));
  //     }
  //     setActiveProfiles(activeData);
  //     localStorage.setItem('ActiveProfilesAMS', JSON.stringify(activeData));
  //     let inActiveData =
  //       data?.data && data?.data?.profiles
  //         ? data?.data?.profiles.filter((inactive) => {
  //           return inactive.ams.connected === false;
  //         })
  //         : [];
  //     setInActiveProfiles(inActiveData);
  //   }
  // }, [doGetProfiles.loading]);

  useEffect(() => {
    const { data, loading } = doGetProfiles;
    console.log('doGetProfiles--data', data);

    if (!loading && data && data.data && data.success === true && Array.isArray(data.data) && data.data.length > 0) {
      console.log('active--', activeProfiles);
      amzDispatch({ type: 'UPDATE_AMAZON_ACTIVE_PROFILES', payload: data.data})
      console.log("cookies", cookies['last-profile'])
      // if (cookies && cookies['last-profile'] != "undefined" && cookies['last-profile'] != undefined && cookies['last-profile'] != null && Object.keys(cookies['last-profile']).length > 0) {
      //   const cookieProfile = cookies['last-profile']
      //   const isExist = data.data.some(
      //     (profile) => profile?.profileOwner === cookieProfile?.profileOwner && profile?.profileId === cookieProfile?.profileId && profile.accessLevel === cookieProfile?.accessLevel && profile?.accountInfo?.name === cookieProfile?.accountInfo?.name
      //   );
      //   console.log("isExist", isExist)
      //   if (isExist) {
      //     const filterProfile = data.data.find(
      //       (profile) => profile?.profileOwner === cookieProfile?.profileOwner && profile?.profileId === cookieProfile?.profileId && profile?.accessLevel === cookieProfile?.accessLevel && profile?.accountInfo?.name === cookieProfile?.accountInfo?.name
      //     );
      //     localStorage.setItem('selectedProfileId', JSON.stringify(filterProfile));
      //   } else {
      //     localStorage.setItem('selectedProfileId', JSON.stringify(data.data.length > 0 ? data.data[0] : {}));
      //     setCookie('last-profile', data.data.length > 0 ? data.data[0] : {});
      //   }
      // } else {
        const selectedProfileId = JSON.parse(localStorage.getItem('selectedProfileId'));
        console.log("selectedProfileId", selectedProfileId)
        if (selectedProfileId && Object.keys(selectedProfileId).length > 0) {
          const isExist = data.data.some(
            (profile) => profile?.profileOwner === selectedProfileId?.profileOwner && profile?.profileId === selectedProfileId?.profileId && profile?.accessLevel === selectedProfileId?.accessLevel && profile?.accountInfo?.name === selectedProfileId?.accountInfo?.name
          );
          if (!isExist) {
            if (data.data && data.data.length > 0) {
              localStorage.setItem('selectedProfileId', JSON.stringify(data.data.length > 0 ? data.data[0] : {}));
              setCookie('last-profile', data.data.length > 0 ? data.data[0] : {});
            } else {
              localStorage.setItem('selectedProfileId', JSON.stringify({}));
            }
          }
        } else {
          localStorage.setItem('selectedProfileId', JSON.stringify(data.data.length > 0 ? data.data[0] : {}));
          setCookie('last-profile', data.data.length > 0 ? data.data[0] : {});
        }
      // }
      localStorage.setItem('active-profiles-ids', JSON.stringify(data.data.map((item) => item.profileId)));
      setCookie('active_profiles', dataEncrypt(data.data))
      setActiveProfiles(data.data);
      setIsAppReady(true)
    } else if (!loading && data && data.success === true && Array.isArray(data.data) && data.data.length == 0) {
      setActiveProfiles([]);
      localStorage.setItem('selectedProfileId', JSON.stringify({}));
      amzDispatch({ type: 'UPDATE_AMAZON_ACTIVE_PROFILES', payload: []})
      setIsAppReady(true)
    } else if (!loading && data && data.success === false) {
      console.log('active--', activeProfiles);
      setActiveProfiles([]);
      localStorage.setItem('selectedProfileId', JSON.stringify({}));
      amzDispatch({ type: 'UPDATE_AMAZON_ACTIVE_PROFILES', payload: []})
      setIsAppReady(true)
    } 
  }, [doGetProfiles.loading]);

  useEffect(() => {
    if (queryParams && Object.keys(queryParams).length > 0) {
      console.log('AMS--adminLayout', queryParams);
      if (isAMS === true) {
        console.log('AMS--admin', queryParams);
        AMSqueryParamsApi(queryParams.code);
      } else if (isSeller === true) {
        console.log('ASP--adminLayout', queryParams);
        doSellerQueryParamsRetrive(queryParams);
      }
    }
  }, []);

  const goToProfile = () => {
    history.push('/profile');
  };

  const goToAddProfile = (routepage) => {
    if (routepage === 'thankyou') {
      history.push('/thankyou');
    } else if (routepage === 'profile') {
      history.push('/profile');
    } else if (routepage == 'addprofile') {
      history.push('/add-profile');
    }
  };

  useEffect(() => {
    if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
    if (layout === 'horizontal' && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: 'vertical' });
    }
  }, [dispatch, layout, windowSize]);

  useEffect(() => {
    const { data, loading } = doAMSQueryParam;
    console.log('doAMSQueryParam--AdminLayout', data);

    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true && data.data && Array.isArray(data.data) && data.data.length > 0) {
      let localData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
      localData['refresh_token'] = data?.data?.refresh_token;
      localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      // AMSretriveToken();
      localData['profiles'] = data?.data;
      localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      localStorage.setItem('AMSexistingProfiles', JSON.stringify([]));
      sweetAlertHandler({
        type: 'success',
        text: data.message ? data.message : 'AMS Authorization Successful',
        showConfirmButton: true,
        action: goToAddProfile('addprofile')
      });
    } else if (!loading && data?.success === true && data.data && Array.isArray(data.data) && data.data.length == 0) {
      sweetAlertHandler({ type: 'danger', text: "No Ad Account Profiles Fetched, Please try again with Correct credentials of Amazon", showConfirmButton: true, action: goToProfile });
    }
  }, [doAMSQueryParam.loading]);

  useEffect(() => {
    const { data, loading } = doAMSProfiles;
    console.log('doAMSProfiles--AdminLayout', data);
    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true && data.data.profiles) {
      let localData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
      localData['profiles'] = data?.data?.profiles;
      localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      localStorage.setItem(
        'AMSexistingProfiles',
        data?.data?.existing_profiles_ids ? JSON.stringify(data?.data?.existing_profiles_ids) : []
      );
      sweetAlertHandler({
        type: 'success',
        text: data.message ? data.message : 'AMS Authorization Successful',
        showConfirmButton: true,
        action: goToAddProfile('addprofile')
      });
    }
  }, [doAMSProfiles.loading]);

  useEffect(() => {
    const { data, loading } = doSellerQueryparam;
    const onboardingType = localStorage.getItem('onboarding');
    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true) {
      let localData = JSON.parse(localStorage.getItem('selectedProfileIdCopy'));
      if (localData) {
        if (onboardingType === 'seller') {
          localData.seller['connected'] = true;
        } else if (onboardingType === 'vendor') {
          localData['vendor'] = {
            connected: true
          };
        }
        localStorage.setItem('selectedProfileId', JSON.stringify(localData));
      }
      sweetAlertHandler({
        type: 'success',
        text: data.message ? data.message : `Amazon ${onboardingType === 'vendor' ? 'Vendor' : 'Seller'} Central Authorization Successful`,
        showConfirmButton: true,
        action: goToAddProfile('profile')
      });
    }
  }, [doSellerQueryparam.loading]);

  useEffect(() => {
    const { data, loading } = doSellerProfiles;
    console.log('doSellerProfiles--AdminLayout', data);
    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true && data.data.profiles) {
      let localData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
      localData['profiles'] = data.data.profiles;
      localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      sweetAlertHandler({ type: 'success', text: data.message, showConfirmButton: true, action: goToProfile });
    }
  }, [doSellerProfiles.loading]);

  const AMSqueryParamsApi = (code) => {
    let ParseData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
    const localUser = JSON.parse(localStorage.getItem('User'));
    const selectedParentProfileId = JSON.parse(localStorage.getItem('selectedParentProfile'))
    const groupConnection = selectedParentProfileId && Object.keys(selectedParentProfileId).length > 0
    if (ParseData && localUser) {
      doAMSQueryParam.request({
        url: groupConnection ? '/aws/auth-group-callback' : '/aws/auth-callback',
        nodeAPI: 1,
        payload: { 
          code: code, 
          marketplace: ParseData.region_name,
          ...(groupConnection ? { parent_profile_id: selectedParentProfileId?.profileId } : {})
        }
      });
    }
  };

  const AMSretriveToken = () => {
    let ParseData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
    const localUser = JSON.parse(localStorage.getItem('User'));
    if (ParseData && localUser) {
      console.log('parseData', ParseData);
      doAMSProfiles.request({
        url: '/ams-retrieve-profiles',
        payload: { token: localUser.token, refresh_token: ParseData.refresh_token, marketplace: ParseData.region_name }
      });
    }
  };

  const doSellerQueryParamsRetrive = (queryParams) => {
    const sellerData = JSON.parse(localStorage.getItem('ASPselectedCountry'));
    const vendorData = JSON.parse(localStorage.getItem('VendorSelectedCountry'));
    const localUser = JSON.parse(localStorage.getItem('User'));
    const onboardingType = localStorage.getItem('onboarding');
    const selectedParentProfileId = JSON.parse(localStorage.getItem('selectedParentProfile'))
    console.log('userFormAdmin', localUser);
    if (
      onboardingType === 'seller' &&
      sellerData &&
      localUser &&
      'ams_profile' in sellerData &&
      sellerData.ams_profile &&
      sellerData.ams_profile.profileId
    ) {
      if(selectedParentProfileId && Object.keys(selectedParentProfileId).length > 0) {
        doSellerQueryparam.request({
          url: '/aws/auth-group-seller-callback',
          nodeAPI: 1,
          payload: {
            grant_code: queryParams.spapi_oauth_code,
            marketplace: sellerData.region_code,
            profile_id: selectedParentProfileId.profileId,
            selling_partner_id: queryParams.selling_partner_id
          }
        });
      } else {
        doSellerQueryparam.request({
          url: '/aws/auth-seller-callback',
          nodeAPI: 1,
          payload: {
            grant_code: queryParams.spapi_oauth_code,
            marketplace: sellerData.region_code,
            profile_id: sellerData.ams_profile.profileId,
            selling_partner_id: queryParams.selling_partner_id
          }
        });
      } 
    } else if (
      onboardingType === 'vendor' &&
      vendorData &&
      localUser &&
      'ams_profile' in vendorData &&
      vendorData.ams_profile &&
      vendorData.ams_profile.profileId
    ) {
      if(selectedParentProfileId && Object.keys(selectedParentProfileId).length > 0) {
        doSellerQueryparam.request({
          url: '/aws/auth-group-vendor-callback',
          nodeAPI: 1,
          payload: {
            grant_code: queryParams.spapi_oauth_code,
            marketplace: vendorData.region_code,
            profile_id: selectedParentProfileId.profileId,
            selling_partner_id: queryParams.selling_partner_id
          }
        });
      } else {
        doSellerQueryparam.request({
          url: '/aws/auth-vendor-callback',
          nodeAPI: 1,
          payload: {
            grant_code: queryParams.spapi_oauth_code,
            marketplace: vendorData.region_code,
            profile_id: vendorData.ams_profile.profileId,
            selling_partner_id: queryParams.selling_partner_id
          }
        });  
      }
    }
  };

  const exitFullscreen = () => {
    document.exitFullscreen();
    document.addEventListener('fullscreenchange', (event) => {
      const element = document.getElementById('panel-wrapper');
      if (document.fullscreenElement) {
        element.classList.add('fscreen-enbld');
      } else {
        element.classList.remove('fscreen-enbld');
      }
    });
  };

  document.onkeydown = function (evt) {
    const element = document.getElementById('panel-wrapper');
    evt = evt || window.event;
    var isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      element.classList.remove('fscreen-enbld');
    }
  };

  console.log('doAMSQueryParam', doAMSQueryParam);
  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  let mainClass = ['pcoded-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    mainClass = [...mainClass, 'container'];
  }

  const fscreenloader = () => {
    setScreenLoader(true);
  };

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      // text: alert.text,
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action
        ? alert.action
        : () => {
          console.log('Actioned');
        },
      allowOutsideClick: false
    });
  };

  // useEffect(() => {
  //   console.log('document?.fullscreen', document?.fullscreen);
  //   if (document?.fullscreen === true) {
  //     setexitOverlay(true);
  //   } else if (document?.fullscreen === true) {
  //     setexitOverlay(false);
  //   }

  //   return () => {
  //     setexitOverlay(false);
  //   };
  // }, [document?.fullscreen]);

  // useEffect(() => {
  //   console.log('document?.fullscreen', document?.fullscreen);
  //   if (document?.fullscreen === true) {
  //     setExitActive(false);
  //   } else if (document?.fullscreen === true) {
  //     setExitActive(false);
  //   }

  //   return () => {
  //     setExitActive(false);
  //   };
  // }, [document?.fullscreen]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const enableFullscreen = () => {
    const element = document.documentElement; // Fullscreen the entire document
    const DocElement = document.getElementById('panel-wrapper');
    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        // Internet Explorer/Edge
        element.msRequestFullscreen();
      }
      DocElement.classList.add('fscreen-enbld');
      document.body.classList.add('full-sceen-on');
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // Internet Explorer/Edge
        document.msExitFullscreen();
      }
      DocElement.classList.remove('fscreen-enbld');
      document.body.classList.remove('full-sceen-on');
    }

    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      const DocElement = document.getElementById('panel-wrapper');
      console.log('document.fullscreenElement', document.fullscreenElement);
      if (document.fullscreenElement == null) {
        DocElement.classList.remove('fscreen-enbld');
        document.body.classList.remove('full-sceen-on');
      }
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  const[isPaymentAlertNotShow, setIsPaymentAlertNotShow] = useState(1)

  // const isPaymentAlert = currentPlan && ((currentPlan?.isTrail === 1 && isDate(new Date(currentPlan?.trialStartDate)) && isDate(new Date(currentPlan?.trialExpireDate)) && differenceInDays(new Date(currentPlan?.trialExpireDate), new Date()) < 8) || (currentPlan?.isTrail === 0 && currentPlan?.nextPayment && isDate(new Date(currentPlan?.nextPayment)) && differenceInDays(new Date(currentPlan?.nextPayment), new Date()) < 8)) && isPaymentAlertNotShow ? 1 : 0
  const isPaymentAlert = 0

  let common = (
    <React.Fragment>
      {isPaymentAlert ? <div className="notify_top">
        <h2 className="f-474444 f-14 f-w-500 l-20 text-capitalize">
          {currentPlan?.message?.replace(', Upgrade now to start subscription', '.')?.replace('your trial period expired on in', 'Your Trial period will expire in')} <span className="f-5932EA f-14 f-w-600 cursor-pointer" onClick={() => {
            window.location.href = '/account-settings/renewal/subscription'
          }}>Upgrade now to start subscription</span>
        </h2>
        <p className='notify_clse' onClick={() => setIsPaymentAlertNotShow(0)}>&#x2715;</p>
      </div> : null}
      <Navigation IsFlipkartUser={process.env.REACT_APP_FLIPKART_ACCESS_EMAIL.indexOf(ParseData?.email) > -1} isTrail={isPaymentAlert === 1 ? true : false} />
    </React.Fragment>
  );

  let mainContainer = (
    <React.Fragment>
      <NavBar isTrail={isPaymentAlert === 1 ? true : false} activeProfiles={activeProfiles} isFullScreen={isFullScreen} enableFullscreen={enableFullscreen} />
      <div className={`pcoded-main-container ${zoom}`} id="panel-wrapper">
        {/* FULL SCREEN LOADER */}
        <section className="h-screen m-auto f-loader">
          <div>
            <img src={chartloader} alt="loader" className="mr-1 wid-80 hei-80" />
          </div>
        </section>
        {/* FULL SCREEN LOADER */}

        {/* EXIT FULL SCREEN BUTTON */}
        {isFullScreen && <div className="fsch-hover" onMouseEnter={() => setExitActive(true)} onMouseLeave={() => setExitActive(false)} />}
        {isFullScreen && isExit === true && (
          <div className="exit-btn hover-theme" onMouseEnter={() => setExitActive(true)}>
            {/* <DropdownButton className="text-capitalize txt-secondary" title="Targeting Summary" variant="white">
                      <Dropdown.Item eventKey="1" active>
                        <Link to="targeting-summary">Targeting Summary</Link>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="2">
                        <Link to="search-term">Search Term</Link>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="3">
                        <Link to="profile">Profile</Link>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="4">
                        <Link to="addaccount">Add Account</Link>
                      </Dropdown.Item>
                    </DropdownButton> */}
            <Minimize
              size={18}
              className="cursor-pointer hover-theme f-20 txt-secondary"
              onClick={() => {
                enableFullscreen();
                setexitOverlay(false);
                setExitActive(true);
              }}
            />
            {/* <Button name="Exit" classes="txt-secondary hover-theme" action={exitFullscreen} /> */}
          </div>
        )}
        {/* EXIT FULL SCREEN BUTTON */}
        <div className={mainClass.join(' ')}>
          <div className={`pcoded-content ${isPaymentAlert ? 'billing_notify' : ''}`}>
            <div className="pcoded-inner-content">
              <Breadcrumb />
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  if (windowSize.width < 992) {
    let outSideClass = ['nav-outside'];
    if (collapseMenu) {
      outSideClass = [...outSideClass, 'mob-backdrop'];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, 'mob-fixed'];
    }

    common = (
      <div className={outSideClass.join(' ')} ref={ref}>
        {common}
      </div>
    );

    mainContainer = (
      <div className="pcoded-outside" onClick={() => mobileOutClickHandler}>
        {mainContainer}
      </div>
    );
  }

  if (doAMSQueryParam.loading || doAMSProfiles.loading || doSellerQueryparam.loading) {
    return (
      <section className="h-screen d-flex flex-column align-items-center justify-content-center">
        <img src={MainLogo} alt="hector" className="mb-4 hei-55" />
        <div class="fastforward mb-3">
          <section className="connect-logo">
            <img src={AmazonLogo} className="wid-45" alt="amazon" />
          </section>

          <section className="d-flex flex-column align-items-center justify-content-center">
            <p className="mb-5 f-333843 f-20 f-w-600">Authorization in progress...</p>
            <div className="load-wrapp">
              <div className="load-10">
                <div className="bar"></div>
              </div>
            </div>
          </section>

          <section className="connect-logo">
            <img src={Logo} className="wid-45" alt="logo" />
          </section>
        </div>
      </section>
    );
  }
  // else if (doAMSProfiles.loading) {
  //   return (
  //     <section className="h-screen d-flex flex-column align-items-center justify-content-center">
  //       <div className="fastforward mb-3">
  //         <section className="connect-logo">
  //           <img src={AmazonLogo} className="wid-50" />
  //         </section>

  //         <div className="load-wrapp">
  //           <div className="load-10">
  //             <div className="bar"></div>
  //           </div>
  //         </div>

  //         <section className="connect-logo">
  //           <img src={Logo} className="wid-50" />
  //         </section>

  //       </div>
  //         <p>Authorization on progress ...</p>
  //     </section>
  //   );
  // }
  // else if (doSellerQueryparam.loading) {
  //   return (
  //     <section className="h-screen d-flex flex-column align-items-center justify-content-center">
  //       <div className="fastforward mb-3">
  //         <section className="connect-logo">
  //           <img src={AmazonLogo} className="wid-50" />
  //         </section>

  //         <div className="load-wrapp">
  //           <div className="load-10">
  //             <div className="bar"></div>
  //           </div>
  //         </div>

  //         <section className="connect-logo">
  //           <img src={Logo} className="wid-50" />
  //         </section>

  //       </div>
  //         <p>Getting Seller Refresh Token</p>
  //     </section>
  //   );
  // }

  if (
    isAppReady &&
    doGetProfiles.loading === false &&
    doGetFlipkartProfiles.loading === false &&
    doGetMyntraProfiles.loading === false &&
    doGetBlinkitProfiles.loading === false &&
    getDateAvailableParams.loading === false
  )
    return (
      <React.Fragment>
        {common}
        {mainContainer}
        {configBlock}
        {commonState?.overlay?.modal?.show === true ? <div className={`${commonState?.overlay?.modal?.classes ? commonState?.overlay?.modal?.classes : 'position-fixed inset-0 z-1500 cusor-pointer'}`} onClick={() => {
          commonState?.overlay?.modal?.closeCallBack()
        }}></div> : null}
      </React.Fragment>
    );
  return (
    <React.Fragment>
      <ThemeProvider>
        <section className="h-screen d-flex align-items-center justify-content-center f-18 f-w-500 text-dark bg-white">
          <img src={ConnectingLoader} alt="Connect" className="wid-40" />
          <StyleTag />
        </section>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default AdminLayout;
