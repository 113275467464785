import icons from 'currency-icons';
import { isNull } from 'lodash';

export const KPI_DICTIONARY = [
  {
    column_type: 'percentage',
    columns: ['CTR', 'CTR %', 'ACOS', 'Total ACOS %', 'Organic ACOS', 'Ad ACOS', 'Direct ACOS', 'InDirect ACOS', 'Spend %', 'Revenue %', 'CR %', 'Orders %', 'CR', 'Total CR %', 'Direct CR %', 'InDirect CR %', 'Spend %', 'Revenue %', 'Total Revenue %', 'Direct Revenue %', 'InDirect Revenue %', 'Total Rev %', 'Direct Rev %', 'InDirect Rev %', 'IS_perc', 'TOIS_perc', 'api_is_perc', 'API IS %', 'API TOS IS %', 'api_tos_perc', 'CR_perc', 'percentage_of_Orders_NTB', 'percentage_of_sales_NTB']
  },
  {
    column_type: 'decimal',
    columns: ['ROAS', 'Total ROAS', 'Organic ROAS', 'Ad ROAS', 'Direct ROAS', 'InDirect ROAS', 'CPO']
  },
  {
    column_type: 'decimal_currency',
    columns: ['CPA', 'Total CPA', 'Direct CPA', 'InDirect CPA', 'AOV', 'Total AOV', 'Direct AOV', 'InDirect AOV', 'CPM', 'CPC', 'RPC']
  },
  {
    column_type: 'currency',
    columns: ['Spend', 'Revenue', 'Total Revenue', 'Direct Revenue', 'InDirect Revenue', 'NTB Sales', 'Total Revenue', 'Total_revenue', 'Organic Revenue', 'Organic_revenue']
  },
  {
    column_type: 'number',
    columns: ['Orders', 'Total Orders', 'Direct Orders', 'InDirect Orders', 'Impressions', 'Clicks', 'CC', 'Total_Impressions', 'Total Revenue', 'Direct Revenue', 'InDirect Revenue']
  }
];

// 'Spend',
//   'Revenue',
//   'Orders',
//   'Impressions',
//   'Clicks',
//   'CPA',
//   'ROAS',
//   'AOV',
//   'CTR',
//   'CPM',
//   'CPC',
//   'ACOS',
//   'RPC',
//   'CPO',
//   'Spend_perc',
//   'Revenue_perc',
//   'CR_perc',
//   'CC'

const KPI_DICTIONARY_TABLE = [
  {
    column_type: 'percentage',
    columns: ['CTR', 'CTR %', 'ACOS', 'TotalACOS', 'OrganicACOS', 'AdACOS', 'DirectACOS', 'InDirectACOS', 'Spend_perc', 'Revenue_perc', 'CR_perc', 'TotalCR', 'Total CR%', 'DirectCR', 'InDirectCR', 'RevenuePercentage', 'TotalRevenuePercentage', 'DirectRevenuePercentage', 'InDirectRevenuePercentage', 'SpendPercentage', 'CR', 'IS_perc', 'TOIS_perc', 'api_is_perc', 'API IS %', 'API TOS IS %', 'api_tos_perc', 'CR_perc', 'percentage_of_Orders_NTB', 'percentage_of_sales_NTB']
  },
  {
    column_type: 'decimal',
    columns: ['ROAS', 'TotalROAS', 'OrganicROAS', 'AdROAS', 'DirectROAS', 'InDirectROAS', 'CPO']
  },
  {
    column_type: 'decimal_currency',
    columns: ['CPA', 'TotalCPA', 'DirectCPA', 'InDirectCPA', 'AOV', 'TotalAOV', 'DirectAOV', 'InDirectAOV', 'CPM', 'CPC', 'RPC']
  },
  {
    column_type: 'currency',
    columns: ['Spend', 'Revenue', 'TotalRevenue', 'DirectRevenue', 'InDirectRevenue', 'Ad Spend', 'Ad Revenue', 'NTB Sales', 'NTB_Sales', 'Total_revenue', 'Organic_revenue']
  },
  {
    column_type: 'number',
    columns: ['Orders', 'TotalOrders', 'DirectOrders', 'InDirectOrders', 'Impressions', 'Clicks', 'CC', 'Ad Orders', 'CampaignCount', 'Total_Impressions', 'NTB Order', 'TotalRevenue', 'DirectRevenue', 'InDirectRevenue']
  }
];

export const PRE_AVAILABLE_COLUMNS_LABELS = window.location.href.indexOf('flipkart') > -1 || window.location.href.indexOf('myntra') > -1 ? [
  {
      "title": "Spend",
      "name": "Spend",
      "show": "yes"
  },
  // {
  //     "title": "Revenue",
  //     "name": "Revenue",
  //     "show": "yes"
  // },
  {
    "title": "Total Revenue",
    "name": "Total Revenue",
    "show": "yes"
  },
  {
    "title": "Direct Revenue",
    "name": "Direct Revenue",
    "show": "yes"
  },
  {
    "title": "InDirect Revenue",
    "name": "InDirect Revenue",
    "show": "yes"
  },
  // {
  //     "title": "Orders",
  //     "name": "Orders",
  //     "show": "yes"
  // },
  {
    "title": "Total Orders",
    "name": "Total Orders",
    "show": "yes"
  },
  {
    "title": "Direct Orders",
    "name": "Direct Orders",
    "show": "yes"
  },
  {
    "title": "InDirect Orders",
    "name": "InDirect Orders",
    "show": "yes"
  },
  {
      "title": "Impression",
      "name": "Impression",
      "show": "yes"
  },
  {
      "title": "Clicks",
      "name": "Clicks",
      "show": "yes"
  },
  // {
  //     "title": "CPA",
  //     "name": "CPA",
  //     "show": "yes"
  // },
  {
    "title": "Total CPA",
    "name": "Total CPA",
    "show": "yes"
  },
  {
    "title": "Direct CPA",
    "name": "Direct CPA",
    "show": "yes"
  },
  {
    "title": "InDirect CPA",
    "name": "InDirect CPA",
    "show": "yes"
  },
  // {
  //     "title": "ROAS",
  //     "name": "ROAS",
  //     "show": "yes"
  // },
  {
    "title": "Total ROAS",
    "name": "Total ROAS",
    "show": "yes"
  },
  {
    "title": "Direct ROAS",
    "name": "Direct ROAS",
    "show": "yes"
  },
  {
    "title": "InDirect ROAS",
    "name": "InDirect ROAS",
    "show": "yes"
  },
  // {
  //     "title": "AOV",
  //     "name": "AOV",
  //     "show": "yes"
  // },
  {
    "title": "Total AOV",
    "name": "Total AOV",
    "show": "yes"
  },
  {
    "title": "Direct AOV",
    "name": "Direct AOV",
    "show": "yes"
  },
  {
    "title": "InDirect AOV",
    "name": "InDirect AOV",
    "show": "yes"
  },
  {
      "title": "CTR %",
      "name": "CTR",
      "show": "yes"
  },
  {
      "title": "CPM",
      "name": "CPM",
      "show": "yes"
  },
  {
      "title": "CPC",
      "name": "CPC",
      "show": "yes"
  },
  // {
  //     "title": "ACOS",
  //     "name": "ACOS",
  //     "show": "yes"
  // },
  {
    "title": "Total ACOS %",
    "name": "Total ACOS %",
    "show": "yes"
  },
  {
    "title": "Direct ACOS",
    "name": "Direct ACOS",
    "show": "yes"
  },
  {
    "title": "InDirect ACOS",
    "name": "InDirect ACOS",
    "show": "yes"
  },
  {
      "title": "Spend %",
      "name": "Spend %",
      "show": "yes"
  },
  // {
  //     "title": "Rev%",
  //     "name": "Rev%",
  //     "show": "yes"
  // },
  {
    "title": "Total Rev %",
    "name": "Total Rev %",
    "show": "yes"
  },
  {
    "title": "Direct Rev %",
    "name": "Direct Rev %",
    "show": "yes"
  },
  {
    "title": "InDirect Rev %",
    "name": "InDirect Rev %",
    "show": "yes"
  },
  // {
  //     "title": "CR%",
  //     "name": "CR%",
  //     "show": "yes"
  // },
  {
    "title": "Total CR %",
    "name": "Total CR %",
    "show": "yes"
  },
  {
    "title": "Direct CR %",
    "name": "Direct CR %",
    "show": "yes"
  },
  {
    "title": "InDirect CR %",
    "name": "InDirect CR %",
    "show": "yes"
  },
  {
      "title": "CC",
      "name": "CC",
      "show": "yes"
  }
]  : [
  {
      "title": "Spend",
      "name": "Spend",
      "show": "yes"
  },
  {
      "title": "Revenue",
      "name": "Revenue",
      "show": "yes"
  },
  {
      "title": "Orders",
      "name": "Orders",
      "show": "yes"
  },
  {
      "title": "Impression",
      "name": "Impression",
      "show": "yes"
  },
  {
      "title": "TOS IS %",
      "name": "TOS IS %",
      "show": "yes"
  },
  {
      "title": "IS %",
      "name": "IS %",
      "show": "yes"
  },
  {
      "title": "ST Rank",
      "name": "ST Rank",
      "show": "yes"
  },
  {
      "title": "Clicks",
      "name": "Clicks",
      "show": "yes"
  },
  {
      "title": "CPA",
      "name": "CPA",
      "show": "yes"
  },
  {
      "title": "ROAS",
      "name": "ROAS",
      "show": "yes"
  },
  {
      "title": "Total ROAS",
      "name": "Total ROAS",
      "show": "yes"
  },
  {
      "title": "Organic ROAS",
      "name": "Organic ROAS",
      "show": "yes"
  },
  {
      "title": "Ad ROAS",
      "name": "Ad ROAS",
      "show": "yes"
  },
  {
      "title": "AOV",
      "name": "AOV",
      "show": "yes"
  },
  {
      "title": "CTR %",
      "name": "CTR",
      "show": "yes"
  },
  {
      "title": "CPM",
      "name": "CPM",
      "show": "yes"
  },
  {
      "title": "CPC",
      "name": "CPC",
      "show": "yes"
  },
  {
      "title": "ACOS",
      "name": "ACOS",
      "show": "yes"
  },
  {
    "title": "Total ACOS %",
    "name": "Total ACOS %",
    "show": "yes"
},
{
    "title": "Organic ACOS",
    "name": "Organic ACOS",
    "show": "yes"
},
{
    "title": "Ad ACOS",
    "name": "Ad ACOS",
    "show": "yes"
},
  {
      "title": "RPC",
      "name": "RPC",
      "show": "yes"
  },
  {
      "title": "CPO",
      "name": "CPO",
      "show": "yes"
  },
  {
      "title": "Spend %",
      "name": "Spend %",
      "show": "yes"
  },
  {
      "title": "Rev %",
      "name": "Rev %",
      "show": "yes"
  },
  {
      "title": "CR %",
      "name": "CR %",
      "show": "yes"
  },
  {
      "title": "CC",
      "name": "CC",
      "show": "yes"
  }
]

export const CustomRound = (number, from = 0) => {
  const decimalPart = number - Math.floor(number); // Get the decimal part of the number
  if (decimalPart > 0.5) {
    return Math.ceil(number); // Round up to the nearest integer
  } else {
    if(from == 1 && number >= 99) {
      return parseInt(number)
    }
    return number; // Round down to the nearest integer
  }
}

const findColumnType = (metricName, table) => {
  const dictionary = table === 1 ? KPI_DICTIONARY_TABLE : KPI_DICTIONARY
  const columnType = dictionary.find(
    (kpi_dic_row) => kpi_dic_row.columns.map((column) => column.toLowerCase().trim()).indexOf(metricName.toLowerCase()) > -1
  );
  return columnType ? columnType : { column_type: 'number' };
};

const getProfile = window.location.href.indexOf('/myntra') > -1 ? JSON.parse(localStorage.getItem('myntraProfile')) : window.location.href.indexOf('/flipkart') > -1 ? JSON.parse(localStorage.getItem('flipkartProfileId')) : JSON.parse(localStorage.getItem('selectedProfileId'));

const intlFormat = (num) => {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
};

export const kpiFormatter = (value, metricName = '', table = 0) => {

  if(value === 'get_currency_sign') {
    return icons[getProfile ? getProfile?.currencyCode : "INR"]?.symbol
  }
  
  const kpiInfo = findColumnType(metricName, table);

  const { column_type } = kpiInfo;

  if(isNull(value) || isNaN(value)) {
    return `${column_type === 'currency' ? icons[getProfile ? getProfile?.currencyCode : "INR"]?.symbol : ''}` + 0 +  `${column_type === 'percentage' ? '%' : ''}`
  }

  if(column_type === 'decimal_currency') {
    if(Math.abs(value) <= 99) {
      return (
        `${icons[getProfile ? getProfile?.currencyCode : "INR"]?.symbol}` +
        Number(value).toFixed(2).toLocaleString(`en-${getProfile ? getProfile.countryCode : "IN"}`)
      );
    }
    
    return (
      `${icons[getProfile ? getProfile?.currencyCode : "INR"]?.symbol}` +
      Number(CustomRound(value, 1)).toLocaleString(`en-${getProfile ? getProfile.countryCode : "IN"}`)
    );
  }

  if (column_type === 'currency' || column_type === 'number') {
    // !(value <= 0)
    return column_type === 'currency' && Math.abs(value) <= 99
      ? `${column_type === 'currency' ? icons[getProfile ? getProfile?.currencyCode : "INR"]?.symbol : ''}` + Number(value).toFixed(2).toLocaleString(`en-${getProfile ? getProfile.countryCode : "IN"}`) :
      `${column_type === 'currency' ? icons[getProfile ? getProfile?.currencyCode : "INR"]?.symbol : ''}` +
      Number(parseInt(value)).toLocaleString(`en-${getProfile ? getProfile.countryCode : "IN"}`
    );
  } else if (column_type === 'decimal') {
    return Math.abs(value) >= 99
      ? Number(parseInt(value)).toLocaleString(`en-${getProfile ? getProfile.countryCode : "IN"}`)
      : Number(value).toFixed(2).toLocaleString(`en-${getProfile ? getProfile.countryCode : "IN"}`)
  } else if (column_type === 'percentage') {
    return `${Number((Math.round(value * 100) / 100)).toFixed(2)} %`
    // return Number(value) >= 10 ? parseInt(value) + '%' : Number((Math.round(value * 100) / 100)).toFixed(2) + '%';
  }

  return value;
};

export const TARGETING_REPORTS =
  window.location.href.indexOf('amazon') > -1
  ? [
      {
        report_id: 'targeting',
        report_title: 'Targeting'
      }
    ]
  : window.location.href.indexOf('flipkart') > -1
    ? [
        {
          report_id: 'targeting',
          report_title: 'Targeting'
        }
      ]
    :  window.location.href.indexOf('myntra') > -1
    ? [
        {
          report_id: 'targeting',
          report_title: 'Targeting'
        }
      ]
    :  window.location.href.indexOf('blinkit') > -1
    ? [
        {
          report_id: 'targeting',
          report_title: 'Targeting'
        }
      ] : [
        {
          report_id: 'targeting',
          report_title: 'Targeting'
        },
        {
          report_id: 'search-term',
          report_title: 'Search Term'
        },
        {
          report_id: 'placement',
          report_title: 'Placement Summary'
        },
        {
          report_id: 'advertised-product',
          report_title: 'Advertised Product Summary'
        }
      ];
