import React from 'react';

const DownChevron = ({ up, ...rest }) => {
  return (
    <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M2 2L14.5 14.5L27 2" stroke="currentColor" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const UpChevron = ({ ...rest }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M0.827591 3.31034L6 8.48276L11.1724 3.31034"
        stroke="white"
        strokeWidth="1.55172"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownChevron;
