export const SearchFilter = (data, keyword, handleSpecific) => {

    const filteredData = data.filter(entry => 
        Object.values(entry).some(val => {
            let lowerCaseVal = typeof val === "string" ? val.toLowerCase().replace(/\s/g, "").replaceAll(/[`~!#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '') : '';
            if(lowerCaseVal == '') {
                lowerCaseVal = typeof val === "object" ? JSON.stringify(val).toLowerCase().replace(/\s/g, "")
                .replace(/[^\w\s]|_/g, "") 
                .replace(/\s+/g, "")  : ''
            }
            const lowerCaseKeyword = keyword.toLowerCase().replace(/\s/g, "").replaceAll(/[`~!#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
            return lowerCaseVal.includes(lowerCaseKeyword); // Use includes() for simpler substring matching
        })
    );
    
    if(handleSpecific === "Table"){
        return filteredData;
    } else {
        return { data, filteredData };
    }

}