import React, { useCallback, useEffect, useRef, useState } from 'react';
import DArrow from 'src/assets/icons/DArrow';
import FilterIcon from 'src/assets/icons/FilterIcon';
import Popover from '@mui/material/Popover';
import DownChevron from 'src/assets/icons/DownChevron';
import Tabs from './tabs';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';

const brandData = [{ label: 'cult' }, { label: 'cult.sport' }, { label: 'HRX' }];

const adAccountData = [
  { label: 'Nua Ad Account', id: 'A2KMPVLFO6FIMC' },
  { label: 'Man Matters Ad Account', id: '7HIN2PVLFO4OICD' }
];

const sellerData = [
  { label: 'Nirogam', id: 'ASKLMF890IMC', profile: 'Seller' },
  { label: 'First step Digital', id: 'ASKLMF890IMC', profile: 'Seller' },
  { label: 'Nua', id: 'ASKLMF890IMC', profile: 'Seller' }
];

const vendorData = [
  { label: 'LifePro Fitness', id: 'ASKLMF890IMC', profile: 'Vendor' },
  { label: 'Ryze', id: 'ASKLMF890IMC', profile: 'Vendor' }
];

export default function index() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
  const doFetchBrandsAccounts = useApi(services.getService);
  const [saveMasterFilters, setSaveMasterFilters] = useState([]);
  const listFilterRef = useRef();
  const [listFilters, setListFilters] = useState({});
  const [includeSBSpends, setIncludeSBSpends] = useState(localStorage.getItem("include-sb-spends") == 'yes' ? true : localStorage.getItem("include-sb-spends") == 'no' ? false : true)

  useEffect(() => {
    doFetchBrandsAccounts.request({
      url: '/profiles/get-group-brand-list',
      nodeAPI: 1,
      payload: {}
    });
  }, []);

  useEffect(() => {
    const { loading, data } = doFetchBrandsAccounts;
    if (!loading && data && data?.success == true && data?.result && Object.keys(data?.result).length > 0) {
      const mainAms = [];
      const mainSeller = [];
      const mainVendor = [];
      const response = data?.result;
      console.log("response", response)
      setListFilters({
        ams: mainAms?.concat(
          response?.ams
            ?.filter((item) => item.profileId != null && item.accountInfo != null)
            ?.map((item) => {
              return {
                label: item?.accountInfo?.sortName ? item?.accountInfo?.sortName : item?.accountInfo?.name ? item?.accountInfo?.name : item?.name ? item?.name : item?.accountName ? item?.accountName : '---',
                value: item?.profileId
              };
            })
        ),
        brands:
          response?.brands?.map((item) => {
            return {
              label: item?.label_name,
              value: item?.label_name
            };
          }) || [],
        seller:
          mainSeller?.concat(
            response?.seller
              ? response?.seller?.map((item) => {
                  return {
                    label: item?.sortName ? item?.sortName : item?.accountName ? item?.accountName : item?.sellerId,
                    value: item?.sellerId
                  };
                })
              : []
          ) || [],
        vendor:
          mainVendor.concat(
            response?.vendor
              ? response?.vendor?.map((item) => {
                  return {
                    label: item?.sortName ? item?.sortName : item?.accountName ? item?.accountName : item?.sellerId,
                    value: item?.sellerId
                  };
                })
              : []
          ) || []
      });
    }
  }, [doFetchBrandsAccounts.loading]);

  useEffect(() => {
    const { loading } = doFetchBrandsAccounts;
    if (!loading && listFilters && Object.keys(listFilters).length > 0) {
      const getExistingMasterFilters = JSON.parse(localStorage.getItem('master-account-filters'));
      if (!(getExistingMasterFilters && getExistingMasterFilters[getProfile?.profileId])) {
        setSaveMasterFilters([
          {
            level: 'brands',
            keywords: listFilters?.brands?.map((item) => item.value)
          },
          {
            level: 'ams',
            keywords: listFilters?.ams?.map((item) => item.value)
          },
          {
            level: 'seller',
            keywords: listFilters?.seller?.map((item) => item.value)
          },
          {
            level: 'vendor',
            keywords: listFilters?.vendor?.map((item) => item.value)
          }
        ]);
        const getExistingMasterFilters = JSON.parse(localStorage.getItem('master-account-filters')) || {};
        if(saveMasterFilters && saveMasterFilters.length > 0) {
            console.log("saveMasterFilters", saveMasterFilters)
            getExistingMasterFilters[getProfile?.profileId] = saveMasterFilters;
            localStorage.setItem('master-account-filters',  JSON.stringify(getExistingMasterFilters))
        }
      } else if (getExistingMasterFilters && getExistingMasterFilters[getProfile?.profileId]) {
        setSaveMasterFilters(getExistingMasterFilters[getProfile?.profileId]);
      }
    }
  }, [doFetchBrandsAccounts.loading, JSON.stringify(listFilters)]);

  const updateSelectedItems = useCallback((event, level, selectItem) => {
    const checked = event?.target?.checked
    const updatedFilters = saveMasterFilters.map((existingItem) => {
        return {
            ...existingItem,
            ...(existingItem.level == level ? {
                keywords: checked ? existingItem?.keywords?.concat([selectItem.value]) : existingItem?.keywords.filter((filterItem) => filterItem != selectItem.value)
            } : {})
        }
    })
    setSaveMasterFilters(updatedFilters)
  }, [saveMasterFilters])

  const updateInCludeSBSpends = useCallback((event) => {
    setIncludeSBSpends(event.target.checked)
  }, [])

  const handleSubmit = useCallback(() => {
    const getExistingMasterFilters = JSON.parse(localStorage.getItem('master-account-filters')) || {};
    if(saveMasterFilters && saveMasterFilters.length > 0) {
        console.log("updatedFilters", saveMasterFilters)
        getExistingMasterFilters[getProfile?.profileId] = saveMasterFilters;
        localStorage.setItem('master-account-filters',  JSON.stringify(getExistingMasterFilters))
    }
    localStorage.setItem('include-sb-spends', includeSBSpends == true ? 'yes' : 'no')
    window.location.reload()
  }, [saveMasterFilters, includeSBSpends])





  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const selectedBrandList = saveMasterFilters?.find((filterItem) => filterItem.level == 'brands' && filterItem?.keywords?.length > 0)?.keywords?.length || 0
  const selectedAdAccountList = saveMasterFilters?.find((filterItem) => filterItem.level == 'ams' && filterItem?.keywords?.length > 0)?.keywords?.length || 0
  const selectedSellerList = saveMasterFilters?.find((filterItem) => filterItem.level == 'seller' && filterItem?.keywords?.length > 0)?.keywords?.length || 0
  const selectedVendorList = saveMasterFilters?.find((filterItem) => filterItem.level == 'vendor' && filterItem?.keywords?.length > 0)?.keywords?.length || 0

  const isAmazonTargeting = window.location.href.indexOf('/amazon/targeting') > -1

  return (
    <React.Fragment>
      <section className="global-filter" onClick={handleClick}>
        <FilterIcon />
        <ul className="global-filter-items">
          {!isAmazonTargeting && <li>
            Brand:
            <span>{selectedBrandList ? selectedBrandList : '---'}</span>
          </li>}

          {!isAmazonTargeting && <li>|</li>}

          <li>
            Ad Account:
            <span>{selectedAdAccountList ? selectedAdAccountList : '---'}</span>
          </li>

          {!isAmazonTargeting && <li>|</li>}

          {!isAmazonTargeting && <li>
            Seller:
            <span>{selectedSellerList ? selectedSellerList : '---'}</span>
          </li>}

          {!isAmazonTargeting && <li>|</li>}

          {!isAmazonTargeting && <li>
            Vendor:
            <span>{selectedVendorList ? selectedVendorList : '---'}</span>
          </li>}
        </ul>
        {/* <DArrow /> */}
        <DownChevron className="dropdown-down-chevron" data-active={Boolean(anchorEl)} width={10} height={10} colour="#8F8F91" />
      </section>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 4px 15px 0px #00000026'
          }
        }}
      >
        <div className="global-filter-tabs">
          <Tabs
            closePopover={handleClose}
            doFetchBrandsAccounts={doFetchBrandsAccounts}
            handleSubmit={handleSubmit}
            updateSelectedItems={updateSelectedItems}
            listFilters={listFilters}
            saveMasterFilters={saveMasterFilters}
            updateInCludeSBSpends={updateInCludeSBSpends}
            includeSBSpends={includeSBSpends}
            setIncludeSBSpends={setIncludeSBSpends}
          />
        </div>
      </Popover>
    </React.Fragment>
  );
}
