import React, { useContext, useEffect, useState, useRef } from 'react';
import { Table, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import infoIcon from 'src/assets/images/common/info.svg';
import Button from 'src/components/Actions/Button';
import ChartIcon from 'src/assets/images/common/chart.svg';
import Downicon from 'src/assets/images/common/down_gray.svg';
import FilterIcon from 'src/assets/images/common/filter.svg';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';
import Spinner from 'react-bootstrap/Spinner';
import { orderBy } from 'lodash';
import icons from 'currency-icons';
import SearchableDropdown from 'src/components/Form/searchable-dropdown';
import Charts from './chart/Chart';
import Radiobutton from 'src/components/Actions/radioButton';
import TREND_CHART from 'src/store/metricsTrendChart.constant';
import { Col } from 'react-bootstrap';
import chartloader from 'src/assets/images/common/chart-loader.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MetricsChart from '../../dashboards/metricsCharts';
import Search from 'src/components/Form/search';
import { SearchFilter } from 'src/helper/search';
import Modal from 'react-bootstrap/Modal';
import { ThemeContext } from 'src/providers/themeProvider';
import Lightloader from 'src/assets/images/common/chart-loader-white.svg';
import { Link } from 'react-router-dom';
import LinkIcon from 'src/assets/images/common/link.svg';
import LinkDarkIcon from 'src/assets/images/common/link-dark.svg';
import ChartLight from 'src/assets/images/common/chart-white.svg';
import FilterLight from 'src/assets/images/common/filter-white.svg';
import brandImg from 'src/assets/images/common/duplicate.png';
import * as XLSX from 'xlsx';
import Input from 'src/components/Form/Input';
import { customNumberFormatter } from 'src/utils/custom-formatters';
import { format } from 'date-fns';
import Calender from 'src/assets/images/common/calender.svg';
import collapseIcon from 'src/assets/images/common/down-collapse-dark.svg';
import collapseLight from 'src/assets/images/common/down-collapse-white.svg';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { getLast7WeekDaysRange, getlast7MonthsRange, getlast7WeeksRange } from '../../snapshot/date.constant.js';
import { ContextMenu, MenuItem } from 'react-contextmenu';

const CollapseArrowIcon = () => {
  const [theme] = useContext(ThemeContext);
  return <img src={theme.theme === 'DARK' ? collapseLight : collapseIcon} alt="date_picker" className="wid-15 ml-2" />;
};

const Loader = () => {
  const [theme] = useContext(ThemeContext);
  return <img src={theme.theme === 'DARK' ? Lightloader : chartloader} className="mr-1 hei-30 wid-30" />;
};

const DEFAULT_SORT_CONFIG = { sort_type: '', sort_column: '', is_sort: false };
const Users = ({
  title,
  value,
  tableInfo,
  keyPrefix,
  selectedDate,
  tableTitle,
  setCrossFilter,
  crossFilterFunction,
  crossFilter,
  subTableInfo,
  classes,
  columnsData = [],
  fromHeatmap,
  fromHarvesting,
  filterObj = {},
  additionalFilter = {},
  withoutCalendar = 0,
  flipkartNodeAPI = 0,
  isDownloadReport = 0
}) => {
  let User = JSON.parse(localStorage.getItem('User'));
  let localUserProfileId =
    window.location.href.indexOf('/flipkart') > -1
      ? JSON.parse(localStorage.getItem('flipkartProfile'))
      : JSON.parse(localStorage.getItem('selectedProfileId'));

  const { table_json, table_id, payload, default_sort, classNames, custom, customDetails } = tableInfo;
  const [isDownloading, setIsDownloading] = useState(false);
  const doAPITargetingSumary = useApi(services.postService);

  const [tableResponse, setTableResponse] = useState({ data: [], summary: [], filteredData: [], total_count: 0 });
  const [selectedTableRes, setSelectedTableRes] = useState([]);
  const [sortConfig, setSortConfig] = useState(default_sort && Object.keys(default_sort).length > 0 ? default_sort : DEFAULT_SORT_CONFIG);
  const [tableCheckbox, setTableCheckBox] = useState('check-box');
  const [valueSearch, setValueSearch] = useState('');
  const [expandWidth, setExpandWidth] = useState('');
  const [expissues, setExpissues] = useState(false);

  const [ScrollConfig, setScrollConfig] = useState({ page: 1, payload: {}, total_count: 0 });
  const doScrollInnerRef = useRef();

  const doDownloadApi = useApi(services.postService);
  const [excelFormat, setExcelFormat] = useState('');

  const doDownloadReport = (typeFormat) => {
    setExcelFormat(typeFormat);
    doDownloadApi.request({
      url: tableInfo.end_point,
      nodeAPI: 1,
      payload: { ...ScrollConfig.payload, is_export: true, export_format: typeFormat, file_type: 'base64' }
    });

    
  };

  const CustomContextMenu = ({ id, onSelectOption, position }) => (
    <div>
      <div className="cat-outclick z-2500" onClick={() => setShowContextMenu(false)}></div>
      <ContextMenu id={id} className="context-menu-card" style={{ top: position.y, left: position.x }}>
        <MenuItem className="context-menu-item" onClick={() => onSelectOption('csv')}>
          Download as csv
        </MenuItem>
        <MenuItem className="context-menu-item" onClick={() => onSelectOption('xlsx')}>
          Download as xlsx
        </MenuItem>
        <MenuItem className="context-menu-item" onClick={() => onSelectOption('xls')}>
          Download as xls
        </MenuItem>
        <MenuItem className="context-menu-item" onClick={() => onSelectOption('gsheet')}>
          Open as sheet
        </MenuItem>
      </ContextMenu>
    </div>
  );

  useEffect(() => {
    if (showContextMenu) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  });

  useEffect(() => {
    const { loading, data } = doDownloadApi;
    if (!loading && data && data.data && data.data.hasOwnProperty('link')) {
      const detailNav = window.open(data.data.link, '_blank');
      if(detailNav) {
        detailNav.focus();
      }
    } else if (!loading && data && data.data && data.data.hasOwnProperty('base64')) {
      const anchor = document.createElement('a');
      anchor.href = `${data.data.base64}`;  
      anchor.target = "_blank";
      anchor.download = `${tableInfo.payload.aggregate}.${excelFormat}`; // Set the desired filename here
      anchor.click();
    } else if (!loading && data) {
      const fileType = excelFormat === 'xlsx'
        ? 'attachment/xlsx'
        : excelFormat === 'xls'
        ? 'application/vnd.ms-excel'
        : excelFormat === 'csv'
        ? 'text/csv'
        : 'application/octet-stream';
        
      console.log("fileType", fileType)
      const anchor = document.createElement('a');
      const fileBlob = new Blob([data], { type: fileType});
      console.log("fileBlob", fileBlob)
      anchor.href = URL.createObjectURL(fileBlob);  
      anchor.target = "_blank";
      anchor.download = `${tableInfo.payload.aggregate}.${excelFormat}`; // Set the desired filename here
      anchor.click();
    }
  }, [excelFormat, doDownloadApi.loading]);

  const isLoading = (tableResponse?.data.length === 0 && tableInfo?.end_point && doAPITargetingSumary.loading) || doDownloadApi.loading;
  const tableInsideLoding =
    (tableResponse?.data.length > 0 && tableInfo?.end_point && doAPITargetingSumary.loading) || doDownloadApi.loading;

  const changeSort = (column_info) => {
    const { sort_type, sort_column, is_sort } = sortConfig;
    console.log('sort_type, sort_column, is_sort', { sort_type, sort_column, is_sort });
    if (table_json?.server_pagination === 'enabled') {
      var element = document.getElementById(`${table_json.aggregate}`);
      if (element && element != undefined) {
        element.scrollTop = 0;
        console.log('Scrollelement.scrollTop', element.scrollTop);
      }
      let sortTo = 'ASC';
      if (sort_type === 'ASC') {
        setSortConfig({ is_sort: true, sort_type: 'DESC', sort_column: column_info.param });
        sortTo = 'ASC';
      } else if (sort_type === 'DESC') {
        setSortConfig({ is_sort: true, sort_type: 'ASC', sort_column: column_info.param });
        sortTo = 'DESC';
      }
      setScrollConfig({
        ...ScrollConfig,
        page: 1,
        payload: { ...ScrollConfig.payload, page: 1, sort_type: sortTo, sort_column: column_info.param }
      });
      doAPITargetingSumary.request({
        url: tableInfo.end_point,
        nodeAPI: flipkartNodeAPI ? flipkartNodeAPI : 0,
        payload: { ...ScrollConfig.payload, page: 1, sort_type: sortTo, sort_column: column_info.param, client: localUserProfileId?.Value }
      });
      return false;
    }

    tableResponse &&
      tableResponse.data.forEach((element) => {
        element[column_info.sortParam ? column_info.sortParam : column_info.param] =
          element && column_info && element[column_info.sortParam ? column_info.sortParam : column_info.param] == null
            ? (element[column_info.sortParam ? column_info.sortParam : column_info.param] = 0)
            : element[column_info.sortParam ? column_info.sortParam : column_info.param];
      });
    const shortingData = tableResponse.data;

    if (is_sort && column_info.label === sort_column) {
      console.log('column_info', column_info);
      if (sort_type === 'ASC') {
        setSortConfig((prev) => ({ ...prev, sort_type: 'DESC' }));
        if (column_info?.field_type === 'Date') {
          setTableResponse((prev) => ({
            ...prev,
            data: orderBy(shortingData, [column_info.sortParam ? column_info.sortParam : column_info.param], ['asc'])
          }));
        } else {
          setTableResponse((prev) => ({
            ...prev,
            data: orderBy(shortingData, [column_info.sortParam ? column_info.sortParam : column_info.param], ['asc'])
          }));
        }
      } else if (sort_type === 'DESC') {
        // setSortConfig(DEFAULT_SORT_CONFIG);
        setTableResponse((prev) => ({
          ...prev,
          data: orderBy(shortingData, [column_info.sortParam ? column_info.sortParam : column_info.param], ['desc'])
        }));
        setSortConfig({ sort_type: 'ASC', sort_column: column_info.label, is_sort: true });
      } else {
        setSortConfig((prev) => ({ ...prev, sort_type: 'ASC' }));
      }
    } else {
      setTableResponse((prev) => ({
        ...prev,
        data: orderBy(shortingData, [column_info.sortParam ? column_info.sortParam : column_info.param], ['desc'])
      }));
      setSortConfig({ sort_type: 'ASC', sort_column: column_info.label, is_sort: true });
    }
  };

  useEffect(() => {
    if (tableInfo && 'end_point' in tableInfo && !doAPITargetingSumary.loading) {
      const crossFiltersData = crossFilter.filter((crossRow) => crossRow.filters.length > 0);
      const payLoadQuery = {
        ...tableInfo.payload,
        profile_id: String(localUserProfileId?.profileId),
        role: localUserProfileId?.accessLevel,
        account_type: localUserProfileId?.accountInfo?.type,
        ...(withoutCalendar != 1 && { start_date: formatDate(selectedDate[0]), end_date: formatDate(selectedDate[1]) }),
        page: 1,
        limit: 50,
        ...(crossFiltersData.length > 0 && { user_filters: crossFiltersData }),
        ...(additionalFilter && Object.keys(additionalFilter).length > 0 && { filters: additionalFilter }),
        ...(columnsData.length > 0 && { metrics: columnsData.filter((column) => column.checked === true).map((mapRow) => mapRow.param) }),
        client: localUserProfileId?.Value
      };
      if (table_json && table_json?.server_pagination === 'enabled') {
        setSortConfig({ ...default_sort });
      }
      var element = document.getElementById(`${table_json.aggregate}`);
      if (crossFiltersData.some((filterRow) => filterRow.aggregate !== table_json.aggregate) && element && element != undefined) {
        element.scrollTop = 0;
        console.log('Scrollelement.scrollTop', element.scrollTop);
      }
      console.log('payLoadQuery', payLoadQuery);
      setScrollConfig({ ...ScrollConfig, payload: payLoadQuery, page: 1 });
      doAPITargetingSumary.request({
        url: tableInfo.end_point,
        nodeAPI: flipkartNodeAPI ? flipkartNodeAPI : 0,
        payload: {
          ...tableInfo.payload,
          profile_id: String(localUserProfileId?.profileId),
          role: localUserProfileId?.accessLevel,
          account_type: localUserProfileId?.accountInfo?.type,
          ...(withoutCalendar != 1 && { start_date: formatDate(selectedDate[0]), end_date: formatDate(selectedDate[1]) }),
          page: 1,
          limit: 50,
          ...(crossFiltersData.length > 0 && { user_filters: crossFiltersData }),
          ...(additionalFilter && Object.keys(additionalFilter).length > 0 && { filters: additionalFilter }),
          ...(columnsData.length > 0 && { metrics: columnsData.filter((column) => column.checked === true).map((mapRow) => mapRow.param) }),
          client: localUserProfileId?.Value
        }
      });
    }
  }, [tableInfo, selectedDate, crossFilter, columnsData]);

  const updateTableResponse = (newData) => {
    const next_page_token = 'next_page_token' in newData && newData.next_page_token ? newData.next_page_token : '';
    const total_count = 'total_count' in newData && newData.total_count ? newData.total_count : '';
    if (table_json?.server_pagination != 'enabled' && sortConfig && Object.keys(sortConfig).length > 0 && sortConfig.is_sort === true) {
      const sortColumn = tableInfo.table_json.columns
        .concat(columnsData.filter((rowInfo) => rowInfo.checked === true))
        .find((sort_row) => sort_row.label == sortConfig.sort_column);
      if (sortColumn) {
        let sort = sortConfig.sort_type.toLowerCase() === 'asc' ? 'desc' : 'asc';
        if (ScrollConfig.page == 1) {
          setTableResponse((prev) => ({
            data: orderBy(newData.data, [sortColumn.sortParam ? sortColumn.sortParam : sortColumn.param], [sort]),
            summary: newData.summary ? newData.summary : [],
            filteredData: valueSearch !== '' ? SearchFilter(newData.data, valueSearch, 'Table') : [],
            next_page_token: next_page_token,
            total_count: total_count
          }));
        } else {
          const overallData = [...tableResponse.data, ...newData.data];
          setTableResponse((prev) => ({
            data: orderBy(overallData, [sortColumn.sortParam ? sortColumn.sortParam : sortColumn.param], [sort]),
            summary: newData.summary ? newData.summary : [],
            filteredData: valueSearch !== '' ? SearchFilter(overallData, valueSearch, 'Table') : [],
            next_page_token: next_page_token,
            total_count: total_count
          }));
        }
      }
    } else {
      if (ScrollConfig.page == 1) {
        setTableResponse({
          data: newData.data,
          summary: newData.summary ? newData.summary : [],
          filteredData: [],
          next_page_token: next_page_token,
          total_count: total_count
        });
      } else {
        const overallData = [...tableResponse.data, ...newData.data];
        setTableResponse({
          data: overallData,
          summary: newData.summary ? newData.summary : [],
          filteredData: [],
          next_page_token: next_page_token,
          total_count: total_count
        });
      }
    }
  };

  useEffect(() => {
    const { data, loading } = doAPITargetingSumary;

    if (!loading) {
      if (!loading && data?.data && Array.isArray(data?.data)) {
        updateTableResponse(data);
      } else if (!loading && data && data.data && data.success === false) {
        setTableResponse({ data: [], summary: [], filteredData: [] });
      } else {
        setTableResponse({ data: [], summary: [], filteredData: [] });
      }
    }
  }, [doAPITargetingSumary.loading]);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  useEffect(() => {
    if (crossFilter && crossFilter.length == 0) {
      setSelectedTableRes([]);
    }
  }, [crossFilter]);

  const headerCheckbox = (str, checked, rowData) => {
    if (!table_json.cross_filter) {
      if (checked === true && table_json.disableIfItemsExist && table_json.disableIfItemsExist.length > 0) {
        const makeCrossFilter =
          crossFilter &&
          crossFilter.length > 0 &&
          crossFilter.some(
            (rowFilter) => table_json.disableIfItemsExist.includes(rowFilter.aggregate) && rowFilter.filters && rowFilter.filters.length > 0
          );
        console.log('table_json.disableIfItemsExist', { tables: table_json.disableIfItemsExist, crossFilter, allow: makeCrossFilter });
        if (makeCrossFilter === true) {
          return;
        }
      }

      if (str === 'header') {
        if (checked === true) {
          console.log('row--', tableInfo.payload.aggregate);
          crossFilterFunction(
            tableInfo.payload.aggregate,
            tableResponse.data.map((data) => data[table_json?.data_id]),
            'true',
            tableInfo.custom === 1 ? tableInfo.table_id : '',
            'customColumn' in tableInfo && tableInfo.customColumn === 1 ? tableInfo.column_name : ''
          );
          setSelectedTableRes(tableResponse.data.map((data) => data[table_json?.data_id]));
        } else if (checked === false) {
          setSelectedTableRes([]);
          crossFilterFunction(
            tableInfo.payload.aggregate,
            [],
            'false',
            tableInfo.custom === 1 ? tableInfo.table_id : '',
            'customColumn' in tableInfo && tableInfo.customColumn === 1 ? tableInfo.column_name : ''
          );
        }
      } else if (str === 'rowData') {
        if (checked === true) {
          setTableCheckBox('close-check');
          console.log('row-', tableInfo.payload.aggregate);
          crossFilterFunction(
            tableInfo.payload.aggregate,
            rowData[table_json?.data_id],
            'true',
            tableInfo.custom === 1 ? tableInfo.table_id : '',
            'customColumn' in tableInfo && tableInfo.customColumn === 1 ? tableInfo.column_name : ''
          );
          setSelectedTableRes((prev) => prev.concat([rowData[table_json?.data_id]]));
        } else if (checked === false) {
          console.log('selectedTableRes', selectedTableRes);
          crossFilterFunction(
            tableInfo.payload.aggregate,
            rowData[table_json?.data_id],
            'false',
            tableInfo.custom === 1 ? tableInfo.table_id : '',
            'customColumn' in tableInfo && tableInfo.customColumn === 1 ? tableInfo.column_name : ''
          );
          let sliceSelectedData = selectedTableRes.filter((Data) => Data !== rowData[table_json?.data_id]);
          setSelectedTableRes(sliceSelectedData);
        }
      }
    }
  };

  const searchInputHandle = async (e) => {
    const keyword = e.target.value;
    const { data, filteredData } = await SearchFilter(tableResponse.data, keyword);

    if (keyword !== '') {
      setTableResponse({ ...tableResponse, filteredData });
    } else {
      setTableResponse({ ...tableResponse, filteredData: [] });
    }
    setValueSearch(keyword);
  };

  const doOnScroll = () => {
    const { loading } = doAPITargetingSumary;
    const { pagination } = table_json;
    if (pagination === 'enabled' && doScrollInnerRef.current && tableResponse.total_count !== tableResponse.data.length && !loading) {
      const { scrollTop, scrollHeight, clientHeight } = doScrollInnerRef.current;
      console.log('E-Scroll { scrollTop, scrollHeight, clientHeight }', { scrollTop, scrollHeight, clientHeight });
      console.log('{ scrollTop, scrollHeight, clientHeight }', {
        scrollTop,
        scrollHeight,
        clientHeight,
        diff: Math.abs(scrollTop + clientHeight - scrollHeight)
      });
      const diffHeight = Math.abs(scrollTop + clientHeight - scrollHeight);
      if (scrollTop + clientHeight === scrollHeight || diffHeight <= 1) {
        console.log('E-Scroll Ended', ScrollConfig);
        setScrollConfig({ ...ScrollConfig, page: ScrollConfig.page + 1 });
        doAPITargetingSumary.request({
          url: tableInfo.end_point,
          nodeAPI: flipkartNodeAPI ? flipkartNodeAPI : 0,
          payload: {
            ...ScrollConfig.payload,
            page: ScrollConfig.page + 1,
            next_page_token: tableResponse.next_page_token,
            client: localUserProfileId?.Value
          }
        });
      }
    }
  };

  const doDownloadData = () => {
    function removeColorIndexProperties(obj) {
      const newObj = {};
      for (const key in obj) {
        if (!key.includes('_colorIndex')) {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    }

    function formatObjectKeys(obj) {
      const formattedObj = {};
      for (const key in obj) {
        const formattedKey = key
          .replace(/_/g, ' ') // Replace underscores with spaces
          // .replace(/([A-Z])/g, ' $1')  // Add space before capital letters
          .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) // Start of each word with a capital letter
          .trim(); // Remove any leading/trailing spaces
        formattedObj[formattedKey] = obj[key];
      }
      return formattedObj;
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = `${table_json.aggregate}_${new Date().getDate()}_${new Date().getMonth()}_${new Date().getFullYear()}`;

    let formattedData = tableResponse?.data
      .map((item) => removeColorIndexProperties(item))
      .concat(tableResponse?.summary ? tableResponse?.summary : []);

    formattedData = formattedData.map((item) => formatObjectKeys(item));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: [`data`] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName + fileExtension);
    document.body.appendChild(link);
    link.click();
  };

  const disableCheckBox =
    table_json.disableIfItemsExist &&
    table_json.disableIfItemsExist.length > 0 &&
    crossFilter &&
    crossFilter.length > 0 &&
    crossFilter.some(
      (rowFilter) => table_json.disableIfItemsExist.includes(rowFilter.aggregate) && rowFilter.filters && rowFilter.filters.length > 0
    );
   
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [showContextMenu, setShowContextMenu] = useState(false);

  const doContextmenu = (event) => {
    event.preventDefault(); // prevent the default behaviour when right clicked
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setShowContextMenu(true);
  };

  const doExportOptions = (option) => {
    setShowContextMenu(false);
    api_call(option)
  };

  const api_call = (fileType) => {
    doDownloadReport(fileType)
  }

  

  return (
    <React.Fragment>
      <section className="d-flex align-items-end justify-content-end mb-2">
        {/* <div className='d-flex align-items-start'>
        <Form>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            label="Graph Mode"
            className="f-13 lab-fix mr-2"
          />
        </Form>
        </div> */}
        <div className="d-flex align-items-end">
          {table_json && table_json.search === 'enable' ? <Search searchValue={valueSearch} callBack={searchInputHandle} /> : null}
          {/* <Button
            classes="py-2.5 px-3 primary-btn f-13 f-w-600 ml-2 text-light align-items-end"
            action={async () => {
              setIsDownloading(true);
              await doDownloadData();
              setIsDownloading(false);
            }}
            name="Download"
            disabled={isDownloading}
          >
            {isDownloading ? <Spinner animation="border" role="status" className="ml-2 wid-15 hei-15" variant="light" /> : null}
          </Button> */}
        </div>
      </section>
      {/* {isDownloadReport === 1 ? <section className="d-flex align-items-end justify-content-end mb-3 position-relative">
        <div className={`fil_drop ${doDownloadApi.loading ? 'disabled' : ''}`} onClick={() => setExpissues(!expissues)}>
          Export
          <img src={Downicon} alt="dropdown" className="wid-20" />
        </div>
        {expissues && (
          <div className="exp_mod">
            <ul>
              <li onClick={() => {
                setExpissues(!expissues)
                doDownloadReport('xlsx')
              }}>XLSX</li>
              <li onClick={() => {
                setExpissues(!expissues)
                doDownloadReport('csv')
              }}>CSV</li>
            </ul>
          </div>
        )}
      </section> : null} */}
      <section
        className={`data-tab mb-4 position-relative ${classes} ${tableInsideLoding ? 'skel-loader' : ''} ${classNames ? classNames : ''}`}
      >
        {(table_json.aggregate == 'Brand' &&
          table_json.aggregate == 'Category' &&
          table_json.aggregate == 'Subcategory' &&
          !doAPITargetingSumary.loading &&
          tableResponse &&
          tableResponse.data.length === 0 &&
          localUserProfileId.accessLevel === 'Executive' && <div className="notify_overlay">Please Complete a Categorization</div>) ||
          (table_json.aggregate == 'Brand' &&
            table_json.aggregate == 'Category' &&
            table_json.aggregate == 'Subcategory' &&
            !doAPITargetingSumary.loading &&
            tableResponse &&
            tableResponse.data.length === 0 &&
            localUserProfileId.accessLevel !== 'Executive' && (
              <div className="notify_overlay">
                <p>
                  Please Complete a Categorization,
                  <Link to="/categorization" className="mx-1 txt-theme">
                    Click here
                  </Link>{' '}
                  to Redirect the Categorization
                </p>
              </div>
            ))}
        <div className="overflow-hidden out-box">
          {(!doAPITargetingSumary.loading &&
            tableInfo?.end_point &&
            tableResponse.data.length === 0 &&
            table_json.aggregate !== 'Brand' &&
            table_json.aggregate !== 'Category' &&
            table_json.aggregate !== 'Subcategory' && <div className="table-emptymsg"> No Data Found</div>) ||
            (valueSearch !== '' && !doAPITargetingSumary.loading && tableResponse.filteredData.length === 0 && (
              <div className={`table-emptymsg fix-emptydata`} style={{ zIndex: '99999' }}>
                {' '}
                No Data Found
              </div>
            ))}
          {isLoading && (
            <div className="tab-loader">
              {' '}
              <Spinner animation="border" role="status" className="ml-2 wid-30 hei-30" variant="secondary" />
            </div>
          )}

          {tableInsideLoding && (
            <div className="linear-bg">
              <div className="linear-track" />
            </div>
          )}

        {showContextMenu ? (
          <CustomContextMenu id={`${table_json.aggregate}_custom-context-menu`} onSelectOption={doExportOptions} position={contextMenuPosition} />
        ) : null}

          <div
            onContextMenu={doContextmenu}
            id={`${table_json.aggregate}`}
            className={`scroller table-height ${tableResponse.data.length > 0 && tableResponse.summary.length === 0 ? 'fit-height' : ''}`}
            ref={doScrollInnerRef}
            onScroll={doOnScroll}
          >
            <Table>
              <thead>
                {/* checked={setSelectedTableRes !== [] ? true : false} */}
                <tr>
                  <th className={`pin ${doAPITargetingSumary?.loading ? 'pe-none cursor-notallowed' : 'cursor-pointer'}`}>
                    <div className={`form-group check-box table-check ${disableCheckBox ? 'disabled' : ''}`}>
                      <input
                        type="checkbox"
                        className="mr-2"
                        id={'RenderHeader-' + table_id}
                        disabled={disableCheckBox}
                        checked={
                          crossFilter.length > 0 &&
                          crossFilter.some(
                            (crossRow) =>
                              (!custom &&
                                crossRow.aggregate === tableInfo.payload.aggregate &&
                                crossRow?.filters?.length === tableResponse.data.length) ||
                              (custom === 1 &&
                                crossRow.aggregate === tableInfo.payload.aggregate &&
                                crossRow?.filters?.length === tableResponse.data.length &&
                                crossRow.table_id === customDetails.table_id)
                          )
                        }
                        onChange={(e) => headerCheckbox('header', e.target.checked, '')}
                      />
                      <label htmlFor={'RenderHeader-' + table_id} />
                    </div>
                  </th>
                  {tableInfo.table_json.columns
                    .concat(columnsData.filter((rowInfo) => rowInfo.checked === true))
                    .map((column_row, index) => {
                      return (
                        <React.Fragment key={'RenderHeader-' + index + '-' + table_id}>
                          <RenderHeader
                            column_row={column_row}
                            index={index}
                            callBack={changeSort}
                            sortConfig={sortConfig}
                            tableinfo={tableInfo}
                            selectedDate={selectedDate}
                            doAPITargetingSumary={doAPITargetingSumary}
                            tableResponse={tableResponse}
                            setExpandWidth={setExpandWidth}
                            table_json={table_json}
                          />
                        </React.Fragment>
                      );
                    })}
                </tr>
              </thead>

              {/* {tableInfo && !('end_point' in tableInfo) && (
                <tbody>
                  {tableResponse.data.map((data, index) => {
                    return (
                      <RenderData
                        key={'RenderDatas-' + index + '-' + table_id}
                        checkEnabled={1}
                        table_id={tableInfo.table_id}
                        columns={tableInfo.table_json.columns}
                        data={data}
                        index={index}

                      />
                    );
                  })}
                </tbody>
              )} */}

              {tableInfo &&
                'end_point' in tableInfo &&
                // !doAPITargetingSumary?.loading &&
                tableResponse &&
                tableResponse?.data && (
                  <tbody>
                    {valueSearch !== '' && tableResponse.filteredData.length === 0 && (
                      // return (
                      <tr>
                        <td className="hei-100" colSpan={18}></td>
                      </tr>
                    )}
                    {[...(tableResponse.filteredData && valueSearch !== '' ? tableResponse.filteredData : tableResponse.data)].map(
                      (data, index) => {
                        return (
                          <React.Fragment key={'RenderDatas-' + index + '-' + table_id}>
                            <RenderData
                              checkEnabled={1}
                              selectedDataIds={selectedTableRes}
                              data_id={table_json?.data_id}
                              table_id={tableInfo.table_id}
                              columns={tableInfo.table_json.columns.concat(columnsData.filter((rowInfo) => rowInfo.checked === true))}
                              data={data}
                              index={index}
                              onchangeCallBack={headerCheckbox}
                              disabledCheckbox={disableCheckBox}
                              currencyCode={localUserProfileId?.currencyCode}
                              doAPITargetingSumary={doAPITargetingSumary}
                              tableResponse={tableResponse}
                              fromHeatmap={fromHeatmap}
                              expandWidth={expandWidth}
                              table_json={table_json}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    {tableResponse.data.length > 0 &&
                      tableResponse?.summary.length > 0 &&
                      tableResponse?.summary.map((data, index) => {
                        return (
                          <React.Fragment key={'Summary-RenderDatas-' + index + '-' + table_id}>
                            <RenderData
                              checkEnabled={0}
                              table_id={tableInfo.table_id}
                              columns={tableInfo.table_json.columns.concat(columnsData.filter((rowInfo) => rowInfo.checked === true))}
                              data={data}
                              index={index}
                              disabledCheckbox={disableCheckBox}
                              currencyCode={localUserProfileId?.currencyCode}
                              summary={'summary'}
                              // tableResponse={tableResponse}
                            />
                          </React.Fragment>
                        );
                      })}
                  </tbody>
                )}
            </Table>
          </div>
        </div>

        {/* <div className="d-flex rounded-bottom align-items-center justify-content-center border-left border-right border-bottom p-4 table-footer bg-white position-relative">
          <ReactPaginate
            // showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total}`}
            breakLabel="..."
            // nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={100}
            // previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active'}
          />
        </div> */}
      </section>
    </React.Fragment>
  );
};

const RenderHeader = ({
  index,
  column_row,
  callBack,
  sortConfig,
  tableinfo,
  selectedDate,
  doAPITargetingSumary,
  setExpandWidth,
  table_json
}) => {
  const resizableRef = useRef('');
  const [theme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = useState(false);

  const handleResize = () => {
    const expandWidth = resizableRef.current.offsetWidth;

    setExpandWidth(expandWidth);
  };

  if (index === 0)
    return (
      <th className={`pin1 ${tableinfo && tableinfo.table_json && tableinfo.table_json?.expand_row === 'enable' ? 'res_border' : ''}`}>
        <div
          ref={resizableRef}
          onMouseMove={handleResize}
          className={`d-flex align-items-center ${
            tableinfo && tableinfo.table_json && tableinfo.table_json?.expand_row === 'enable' ? 'expand_cell' : ''
          } ${doAPITargetingSumary?.loading ? 'pe-none' : 'cursor-pointer'}`}
        >
          {sortConfig.is_sort && sortConfig.sort_column === column_row.label && (
            <span className="mr-2">
              {sortConfig.sort_type === 'DESC' && <p className={`uparrow`}></p>}
              {sortConfig.sort_type === 'ASC' && <p className={`downarrow`}></p>}
            </span>
          )}
          <div onClick={() => callBack(column_row)}> {column_row.label} </div>
          {/* <Popover content={column_row.label}>
            <img src={infoIcon} className="wid-15 ml-2 cursor-pointer" alt="info" />
          </Popover> */}
          {tableinfo && tableinfo.table_json && tableinfo.table_json?.trend_chart === 'enable' && (
            <div>
              <img
                src={theme.theme === 'DARK' ? ChartLight : ChartIcon}
                className="wid-15 ml-2 cursor-pointer"
                alt="info"
                onClick={() => setModalShow(!modalShow)}
              />
              {modalShow && (
                <ChartModal show={modalShow} close={() => setModalShow(!modalShow)} tableJson={tableinfo} selectedDate={selectedDate} />
              )}
            </div>
          )}

          {table_json && 'categorizationLink' in table_json && (table_json.categorizationLink != null) != '' ? (
            <div>
              &nbsp;
              <a className="f-10" target="_self" href={table_json.categorizationLink}>
                <img src={theme.theme === 'DARK' ? LinkDarkIcon : LinkIcon} className="cursor-pointer hover-theme wid-15" />
              </a>
            </div>
          ) : null}
        </div>
      </th>
    );
  return (
    <th
      key={'column_th' + index}
      className={`${column_row.class ? column_row.class : ''} ${column_row.param == 'campaign_state_change' ? 'cus-wid-15' : ''}`}
    >
      <div className="d-flex align-items-center  justify-content-start" onClick={() => callBack(column_row)}>
        {sortConfig.is_sort && sortConfig.sort_column === column_row.label && (
          <span className="mr-2">
            {sortConfig.sort_type === 'DESC' && <p className={`uparrow`}></p>}
            {sortConfig.sort_type === 'ASC' && <p className={`downarrow`}></p>}
          </span>
        )}
        {column_row.label}
      </div>
    </th>
  );
};

const RenderBody = ({
  data,
  column_row,
  index,
  currencyCode,
  table_id,
  additionalKey,
  doAPITargetingSumary,
  summary,
  tableResponse,
  fromHeatmap,
  expandWidth,
  table_json,
  campaignInfo,
  setCampaignInfo,
  updateCampaignInfo,
  campaignLoading
}) => {
  const [theme] = useContext(ThemeContext);
  const [showBugetModal, setShowBugetModal] = useState(false);
  const { column_type, prefix_sign, label, param, checkValue, imageIsAvailable, post_sign } = column_row;
  let localUserProfileId =
    window.location.href.indexOf('/flipkart') > -1
      ? JSON.parse(localStorage.getItem('flipkartProfile'))
      : JSON.parse(localStorage.getItem('selectedProfileId'));
  const intlFormat = (num) => {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  };

  const makeFriendly = (num) => {
    if (!num) return 0;
    if (param === 'match_st' && num === checkValue) return 'Live';
    if (param === 'match_st' && num !== checkValue) return '---';

    // if (column_type === 'percentage') return Number(num) >= 10 ? parseInt(num) + '%' : (Math.round(num * 100) / 100).toFixed(2) + '%';
    if (column_type === 'percentage')
      return (Math.round(num * 100) / 100).toFixed(2).toLocaleString('en-' + localUserProfileId.countryCode) + '%';
    // if (column_type === 'decimal') return (Math.round(num * 100) / 100).toFixed(2).toLocaleString('en-' + localUserProfileId.countryCode)
    if (column_type === 'decimal')
      return num >= 10
        ? Number(parseInt(num)).toLocaleString('en-' + localUserProfileId.countryCode)
        : Number(num)
            .toFixed(2)
            .toLocaleString('en-' + localUserProfileId.countryCode); // parseInt(num)
    if (column_type === 'number')
      return Number(parseInt(num)).toLocaleString('en-' + localUserProfileId.countryCode) + `${post_sign ? post_sign : ''}`;
    if (num >= 1000000000) return intlFormat(num / 1000000000) + 'B';
    if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
    // if (num >= 1000) return intlFormat(num / 1000) + 'K';
    return intlFormat(num);
  };

  const currencyIconFormat = () => {
    if (prefix_sign === 'currency') {
      let geticon = icons[currencyCode];
      return geticon?.symbol;
    } else {
      return '';
    }
  };

  function formatAmazonUrl(country, productId) {
    let amazonUrl = 'https://www.amazon.com'; // Default to the US Amazon URL

    // Check the country code and update the URL accordingly
    if (country === 'CA') {
      amazonUrl = 'https://www.amazon.ca';
    } else if (country === 'UK') {
      amazonUrl = 'https://www.amazon.com.uk';
    } else if (country === 'IN') {
      amazonUrl = 'https://www.amazon.in';
    } else if (country === 'US') {
      amazonUrl = 'https://www.amazon.com';
    } else if (country === 'BR') {
      amazonUrl = 'https://www.amazon.com.br';
    } else if (country === 'MX') {
      amazonUrl = 'https://www.amazon.com.mx';
    } else if (country === 'ES') {
      amazonUrl = 'https://www.amazon.com.br';
    } else if (country === 'FR') {
      amazonUrl = 'https://www.amazon.com.br';
    } else if (country === 'NL') {
      amazonUrl = 'https://www.amazon.nl';
    } else if (country === 'DE') {
      amazonUrl = 'https://www.amazon.com.br';
    } else if (country === 'IT') {
      amazonUrl = 'https://www.amazon.com.br';
    } else if (country === 'SE') {
      amazonUrl = 'https://www.amazon.se';
    } else if (country === 'PL') {
      amazonUrl = 'https://www.amazon.pl';
    } else if (country === 'EG') {
      amazonUrl = 'https://www.amazon.eg';
    } else if (country === 'TR') {
      amazonUrl = 'https://www.amazon.com.tr';
    } else if (country === 'SA') {
      amazonUrl = 'https://www.amazon.com.sa';
    } else if (country === 'AE') {
      amazonUrl = 'https://www.amazon.ae';
    } else if (country === 'SG') {
      amazonUrl = 'https://www.amazon.sg';
    } else if (country === 'AU') {
      amazonUrl = 'https://www.amazon.com.au';
    } else if (country === 'JP') {
      amazonUrl = 'https://www.amazon.co.jp';
    }

    // Append the product ID to the URL
    amazonUrl += '/dp/' + productId;
    console.log('amazonUrl', amazonUrl);
    return amazonUrl;
  }

  function getRowClassByIndex(rowData, param) {
    const paramLowerCase = param.toLowerCase();
    if (param) {
      // const filteredData =
      //   tableResponse &&
      //   tableResponse.data &&
      //   tableResponse.data.filter((item) => (item[param] === null ? (item[param] = 0) : item[param]));
      if (rowData[param] && rowData[`${param}_colorIndex`] !== null) {
        // const findHighestValue = orderBy(filteredData, [param], ['desc'])[0][param];
        // const findPerc = parseInt((rowData / findHighestValue) * 100);

        // console.log("getRowClassByIndex", {
        //   rowData, param, colorIndex: rowData[`${param}_colorIndex`], paramLowerCase
        // })

        switch (rowData[`${param}_colorIndex`]) {
          case 10:
            return paramLowerCase + '_100';

          case 9:
            return paramLowerCase + '_90';

          case 8:
            return paramLowerCase + '_80';
          case 7:
            return paramLowerCase + '_70';

          case 6:
            return paramLowerCase + '_60';

          case 5:
            return paramLowerCase + '_50';

          case 4:
            return paramLowerCase + '_40';

          case 3:
            return paramLowerCase + '_30';

          case 2:
            return paramLowerCase + '_20';

          case 1:
            return paramLowerCase + '_10';

          case 0:
            return paramLowerCase + '_0';

          default:
            return paramLowerCase + '_0';
        }
      }
      return paramLowerCase + '_0';
    }
    return paramLowerCase + '_0';
  }

  function getRowClass(rowData, param) {
    if (param) {
      const filteredData =
        tableResponse &&
        tableResponse.data &&
        tableResponse.data.filter((item) => (item[param] === null ? (item[param] = 0) : item[param]));
      if (filteredData !== undefined) {
        const findHighestValue = orderBy(filteredData, [param], ['desc'])[0][param];
        const findPerc = parseInt((rowData / findHighestValue) * 100);
        const paramLowerCase = param.toLowerCase();

        switch (true) {
          case findPerc >= 91 && findPerc <= 100:
            return paramLowerCase + '_100';
            break;

          case findPerc >= 81 && findPerc <= 90:
            return paramLowerCase + '_90';
            break;

          case findPerc >= 71 && findPerc <= 80:
            return paramLowerCase + '_80';
            break;

          case findPerc >= 61 && findPerc <= 70:
            return paramLowerCase + '_70';
            break;

          case findPerc >= 51 && findPerc <= 60:
            return paramLowerCase + '_60';
            break;

          case findPerc >= 41 && findPerc <= 50:
            return paramLowerCase + '_50';
            break;

          case findPerc >= 31 && findPerc <= 40:
            return paramLowerCase + '_40';
            break;

          case findPerc >= 21 && findPerc <= 30:
            return paramLowerCase + '_30';
            break;

          case findPerc >= 11 && findPerc <= 20:
            return paramLowerCase + '_20';
            break;

          case findPerc >= 1 && findPerc <= 10:
            return paramLowerCase + '_10';
            break;

          case findPerc === 0:
            return paramLowerCase + '_0';
            break;

          default:
            break;
        }
      }
    }
  }

  const imageOnError = (event) => {
    event.currentTarget.src =
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTelVna9__Qwt9GifGdE0R4FmsiTmZjoSE1vnC4LXdgozvqbjiOGufuXrladHL7nXowTt4&usqp=CAU';
    event.currentTarget.className = 'mx-1 wid-30 error';
  };

  const generateId = () => {
    const randomId = 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36);
    return randomId;
  };

  if (index === 0)
    return (
      <th className={`pin1 ${column_row.param === 'AdvertisedASINs' && data[column_row.param] !== 'Grand Total' ? 'show-link' : ''}`}>
        {/* {data[column_row.param].length > 28 ?
          <Popover content={data[column_row.param]}>
            <label htmlFor={additionalKey} className="txt-truncate-200 cursor-pointer">{data[column_row.param]}</label>
          </Popover> : */}
        <div className="exp_data d-flex align-items-center">
          {imageIsAvailable === 'yes' && summary !== 'summary' ? (
            <img onError={imageOnError} src={data?.image ? data?.image : ''} className={`mx-1 wid-30`} alt="brand-img" />
          ) : null}
          <label
            style={{ width: `${expandWidth !== null || !expandWidth ? expandWidth : ''}px` }}
            htmlFor={additionalKey}
            className={`${
              imageIsAvailable === 'yes' && summary !== 'summary' ? 'wid-644' : ''
            } txt-truncate-200 show-link d-flex align-items-center ${
              data[column_row.param] === 'Grand Total' || summary === 'summary' ? 'pe-none' : ''
            } ${doAPITargetingSumary?.loading ? 'pe-none cursor-notallowed' : 'cursor-pointer'}`}
            title={data[column_row?.field_display ? column_row?.field_display : column_row.param]}
          >
            {imageIsAvailable === 'yes' && summary !== 'summary' ? (
              <div className="mt-2">
                <p className="f-12 f-w-500 text-dark mb-1">{data?.asin}</p>
                <span className="f-10 text-dark">{data?.product_name}</span>
              </div>
            ) : (
              <>
                {/* {data[column_row?.field_display ? column_row?.field_display : column_row?.param] && data[column_row?.field_display ? column_row?.field_display : column_row?.param].length > 25 ?
          <Popover content={data[column_row?.field_display ? column_row?.field_display : column_row?.param]}>
            {summary === 'summary'
                  ? 'Grand Total'
                  : data[column_row?.field_display ? column_row?.field_display : column_row?.param]}
          </Popover> :   */}
                {summary === 'summary' ? 'Grand Total' : data[column_row?.field_display ? column_row?.field_display : column_row?.param]}
                {/* } */}
              </>
            )}
            {column_row.param === 'AdvertisedASINs' && data[column_row.param] !== 'Grand Total' ? (
              <a
                className="f-10 pass-link"
                target="_blank"
                href={`${formatAmazonUrl(localUserProfileId.countryCode, data[column_row.param])}`}
              >
                <img src={theme.theme === 'DARK' ? LinkDarkIcon : LinkIcon} className="cursor-pointer hover-theme wid-15" />
              </a>
            ) : (
              ''
            )}{' '}
          </label>
        </div>
        {/* } */}

        {/* <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>
              {data[column_row.param]}
            </Tooltip>
          }
        >
          <label htmlFor="checkbox_id"><div className="txt-truncate-200 cursor-pointer">{data[column_row.param]}</div></label>
        </OverlayTrigger> */}
      </th>
    );

  if (column_row.param === 'campaign_state_change')
    return (
      <td
        key={'column_th' + index}
        className={`cursor-pointer ${column_row.class ? column_row.class : ''} ${campaignLoading === true ? 'disabled' : ''}`}
      >
        {summary !== 'summary' ? (
          <Form>
            {campaignLoading === true ? <Spinner animation="border" role="status" className="ml-2 wid-15 hei-15" variant="light" /> : null}
            <Form.Check
              type="switch"
              id={generateId()}
              label=""
              className="f-13 lab-fix wid-80"
              checked={campaignInfo.state.toLowerCase() === 'enabled'}
              onChange={(event) => {
                console.log('event.target', event.target.checked);
                if (event && event.target && 'checked' in event.target) {
                  setCampaignInfo({ ...campaignInfo, state: event.target.checked == true ? 'ENABLED' : 'PAUSED' });
                  updateCampaignInfo('state', { ...campaignInfo, state: event.target.checked == true ? 'ENABLED' : 'PAUSED' });
                }
              }}
            />
          </Form>
        ) : null}
      </td>
    );

  if (column_row.param === 'campaign_budget_change')
    return (
      <td
        key={'column_th' + index}
        className={`cursor-pointer ${column_row.class ? column_row.class : ''} ${campaignLoading === true ? 'disabled' : ''}`}
      >
        {summary === 'summary' ? null : (
          <section className="w-100 d-flex align-items-center justify-content-end position-relative">
            {campaignLoading === true ? <Spinner animation="border" role="status" className="ml-2 wid-15 hei-15" variant="light" /> : null}
            <div className="border p-1 w-fit" onClick={() => setShowBugetModal(true)}>
              {customNumberFormatter(campaignInfo.dailyBudget, 'number', 1)}
            </div>
            {showBugetModal && (
              <section className="bg-white bud_modal">
                <Input
                  types={'number'}
                  values={campaignInfo.tempDailyBudget}
                  onChangeCallback={(event) => {
                    setCampaignInfo({ ...campaignInfo, tempDailyBudget: event.target.value });
                  }}
                />
                <div className="d-flex align-items-center justify-content-end mt-2 w-100 ">
                  <Button
                    name="Save"
                    classes={'primary-btn text-white py-1 f-12 rounded-50'}
                    action={() => {
                      setShowBugetModal(false);
                      setCampaignInfo({ ...campaignInfo, dailyBudget: campaignInfo.tempDailyBudget });
                      updateCampaignInfo('budget', { ...campaignInfo, dailyBudget: campaignInfo.tempDailyBudget });
                    }}
                  />
                  <p
                    className="f-12 txt-theme p-2"
                    onClick={() => {
                      setShowBugetModal(false);
                      setCampaignInfo({ ...campaignInfo, tempDailyBudget: campaignInfo.dailyBudget });
                    }}
                  >
                    Cancel
                  </p>
                </div>
              </section>
            )}
          </section>
        )}
      </td>
    );

  return (
    <td
      key={'column_th' + index}
      className={`cursor-pointer ${column_row.class ? column_row.class : ''} ${
        fromHeatmap === 'heat_map' ? getRowClassByIndex(data, column_row.param) : ''
      }`}
    >
      {/* <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className="custom-tooltip wid-200">
            <div className="d-flex flex-column align-items-start f-13">
              <p className="py-1 border-bottom text-dark w-100 f-w-500">Label</p>
              <p className="text-dark w-100 pt-0 pb-1 f-w-500">Details</p>
            </div>
          </Tooltip>
        }
      >
        <div> */}
      {column_type === 'number' || column_type === 'decimal' || column_type === 'percentage'
        ? `${prefix_sign === 'currency' ? currencyIconFormat() : ''}${makeFriendly(data[column_row.param])}`
        : data[column_row.param]}
      {/* </div>
      </OverlayTrigger> */}
    </td>
  );
};

const RenderData = ({
  data,
  index,
  columns,
  table_id,
  checkEnabled,
  onchangeCallBack,
  selectedDataIds,
  data_id,
  currencyCode,
  doAPITargetingSumary,
  summary,
  tableResponse,
  fromHeatmap,
  expandWidth,
  setExpandWidth,
  table_json,
  custom = 0,
  customDetails = {},
  disabledCheckbox = false
}) => {
  const bodyCellIndex = index;

  const requestCampaign = useApi(services.postService);
  const updateCampaign = useApi(services.putService);
  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));

  const [campaignInfo, setCampaignInfo] = useState({
    campaignId: '',
    state: '',
    dailyBudget: 0,
    tempDailyBudget: 0
  });

  useEffect(() => {
    if (columns.length > 0 && columns.some((column_row) => column_row.param === 'campaign_state_change')) {
      getCampaignInfo();
    }
  }, []);

  const getCampaignInfo = () => {
    requestCampaign.request({
      url: '/campaigns/get-campaign-details',
      nodeAPI: 1,
      payload: { campaignName: data['campaignName'], profile_id: String(getProfile?.profileId) }
    });
  };

  useEffect(() => {
    const { loading, data } = requestCampaign;

    if (!loading && data && data.success === true && data.result && Object.keys(data.result).length > 0) {
      setCampaignInfo({ ...data.result, tempDailyBudget: data.result.dailyBudget });
    }
  }, [requestCampaign.loading]);

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action
        ? alert.action
        : () => {
            console.log('Actioned');
          },
      allowOutsideClick: false,
      showCancelButton: alert.CancelButton
    });
  };

  const saveCampaignInfo = (type, payloadInfo) => {
    if (!payloadInfo.campaignId || payloadInfo.campaignId === '') {
      sweetAlertHandler({ type: 'alert', text: "'campaignId' is missing", showConfirmButton: true });
      return false;
    }
    setCampaignInfo(payloadInfo);
    let updateQuery = {
      campaignId: String(campaignInfo.campaignId),
      profile_id: String(getProfile?.profileId)
      // campaignName: data['campaignName']
    };
    if (type === 'budget') {
      updateQuery = {
        profile_id: String(getProfile?.profileId),
        campaignId: String(campaignInfo.campaignId),
        dailyBudget: Number(payloadInfo.dailyBudget),
        campaigns: [
          {
            // ...updateQuery,
            campaignId: String(campaignInfo.campaignId),
            budget: {
              budgetType: 'DAILY',
              budget: Number(payloadInfo.dailyBudget)
            }
          }
        ]
      };
      updateCampaign.request({ url: '/campaigns/update-campaign-budget-details', nodeAPI: 1, payload: updateQuery });
    } else if (type === 'state') {
      updateQuery = {
        profile_id: String(getProfile?.profileId),
        campaignId: String(campaignInfo.campaignId),
        state: payloadInfo.state,
        campaigns: [
          {
            // ...updateQuery,
            campaignId: String(campaignInfo.campaignId),
            // dailyBudget: campaignInfo.dailyBudget,
            state: payloadInfo.state
          }
        ]
      };
      updateCampaign.request({ url: '/campaigns/update-campaign-details', nodeAPI: 1, payload: updateQuery });
    }
  };

  useEffect(() => {
    const { loading, data } = updateCampaign;
    if (!loading && data && data.success == true) {
      sweetAlertHandler({ type: 'alert', text: data.message ? data.message : 'Updated Successfully', showConfirmButton: true });
      getCampaignInfo();
    } else if (!loading && data && data.success == false) {
      sweetAlertHandler({ type: 'alert', text: data.message ? data.message : 'Failed to Update !!', showConfirmButton: true });
      getCampaignInfo();
    }
  }, [updateCampaign.loading]);

  return (
    <tr
      className={`${selectedDataIds && selectedDataIds.indexOf(data[data_id]) > -1 ? 'active-row' : ''}${
        selectedDataIds?.length > 0 && selectedDataIds.indexOf(data[data_id]) === -1 ? ' opacity-50' : ''
      }`}
    >
      <th className={`pin ${doAPITargetingSumary?.loading ? 'pe-none' : 'cursor-pointer'}`}>
        {checkEnabled === 1 && (
          <div className={`form-group check-box table-check ${disabledCheckbox ? 'disabled' : ''}`}>
            <input
              type="checkbox"
              className="mr-2"
              id={'RenderBody-' + index + '-' + table_id}
              onChange={(e) => {
                onchangeCallBack('rowData', e.target.checked, data);
              }}
              disabled={disabledCheckbox}
              checked={selectedDataIds && selectedDataIds.indexOf(data[data_id]) > -1 ? true : false}
            />
            <label htmlFor={'RenderBody-' + index + '-' + table_id} />
          </div>
        )}
      </th>
      {columns.map((column_row, index) => {
        return (
          <React.Fragment key={'RenderBody-' + index + '-' + table_id}>
            <RenderBody
              data={data}
              additionalKey={`${'RenderBody-' + bodyCellIndex + '-' + table_id}`}
              column_row={column_row}
              table_id={table_id}
              index={index}
              currencyCode={currencyCode}
              doAPITargetingSumary={doAPITargetingSumary}
              summary={summary}
              tableResponse={tableResponse}
              fromHeatmap={fromHeatmap}
              setExpandWidth={setExpandWidth}
              expandWidth={expandWidth}
              table_json={table_json}
              campaignInfo={campaignInfo}
              setCampaignInfo={setCampaignInfo}
              updateCampaignInfo={saveCampaignInfo}
              campaignLoading={updateCampaign.loading || requestCampaign.loading}
            />
          </React.Fragment>
        );
      })}
    </tr>
  );
};

function ChartModal({
  show,
  close,
  tableJson,
  selectedDate,
  addDays,
  subDays,
  startOfDay,
  startOfMonth,
  endOfMonth,
  subMonths,
  endOfDay,
  subWeeks,
  isSameDay,
  isWeekend,
  getDay,
  eachDayOfInterval,
  sub
}) {
  const [theme] = useContext(ThemeContext);

  let localUserProfileId =
    window.location.href.indexOf('/flipkart') > -1
      ? JSON.parse(localStorage.getItem('flipkartProfile'))
      : JSON.parse(localStorage.getItem('selectedProfileId'));
  const doTrendChartApi = useApi(services.postService);

  const [chartDropDownValue, setChartDropDownValue] = useState(TREND_CHART.Trends_data);
  const [selectedMetric, setSelectedMetric] = useState(TREND_CHART.Trends_data[0]);
  const [showfilteroption, setfilteroption] = useState(false);
  const [showDatepicker, setShowdatepicker] = useState(false);

  const [showOption, setShowoption] = useState(false);
  const [breakDownMetrics, setBreakDownMetrics] = useState({ categories: [], series: [] });

  const { table_name, payload, table_json } = tableJson;
  const [dayOrRangeView, setDayOrRangeView] = useState('day');
  const [tempDateRange, setTempDateRange] = useState({
    startDate: addDays(new Date(), -1),
    endDate: addDays(new Date(), -1),
    pastStartDate: addDays(new Date(), -2),
    pastEndDate: addDays(new Date(), -2),
    dates: [],
    compareDates: [],
    key: 'Yesterday'
  });

  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: addDays(new Date(), -1),
      endDate: addDays(new Date(), -1),
      pastStartDate: addDays(new Date(), -2),
      pastEndDate: addDays(new Date(), -2),
      key: ''
      // startDate: startOfMonth(subMonths(new Date(), 1)),
      // endDate: endOfMonth(subMonths(new Date(), 1)),
      // pastStartDate: startOfMonth(subMonths(new Date(), 2)),
      // pastEndDate: endOfMonth(subMonths(new Date(), 2)),
      // key: 'Last Month'
    }
  ]);

  const [dateSelected, setDateSelected] = useState({
    startDate: addDays(new Date(), -1),
    endDate: addDays(new Date(), -1),
    pastStartDate: addDays(new Date(), -2),
    pastEndDate: addDays(new Date(), -2),
    key: 'Yesterday'
    // startDate: startOfMonth(subMonths(new Date(), 1)),
    // endDate: endOfMonth(subMonths(new Date(), 1)),
    // pastStartDate: startOfMonth(subMonths(new Date(), 2)),
    // pastEndDate: endOfMonth(subMonths(new Date(), 2)),
    // key: 'Last Month'
  });

  const saveDateRange = () => {
    if (tempDateRange) {
      console.log('saveDateRange', { tempDateRange });

      setShowdatepicker(false);

      if (isSameDay(tempDateRange.startDate, tempDateRange.endDate)) {
        //  && tempDateRange && format(tempDateRange.startDate, 'yyyy-MM-dd') === format(tempDateRange.endDate, 'yyyy-MM-dd')
        setDayOrRangeView('day');
        setDateSelected(tempDateRange);
      } else if (!isSameDay(tempDateRange.startDate, tempDateRange.endDate)) {
        setDayOrRangeView('month');
        setDateSelected(tempDateRange);
      }
    }
  };

  function dateFormat(dateString) {
    console.log('date', dateString);
    const date = new Date(dateString);
    console.log('date--', date);
    return date;
  }

  const handleStaticRangeFocus = (event) => {
    console.log('handleStaticRangeFocus', event);
  };

  const handleDateClick = (event) => {
    console.log('handleDateClick', event);
  };

  const updateRangeView = (rangeInfo) => {
    // const rangeInfo = selectedDateRange[0];
    console.log('updateRangeView', rangeInfo);

    let dateInfo = {};
    const currentDate = new Date();

    if (rangeInfo.key === 'Yesterday') {
      dateInfo = {
        startDate: subDays(new Date(), 1),
        endDate: subDays(new Date(), 1),
        pastStartDate: subDays(new Date(), 2),
        pastEndDate: subDays(new Date(), 2),
        key: 'Yesterday'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'This Month') {
      dateInfo = {
        startDate: startOfMonth(new Date()),
        endDate: addDays(new Date(), -1) < startOfMonth(new Date()) ? startOfMonth(new Date()) : addDays(new Date(), -1),
        pastStartDate: startOfMonth(subMonths(currentDate, 1)),
        pastEndDate: subMonths(currentDate, 1),
        key: 'This Month'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last Month') {
      dateInfo = {
        startDate: startOfMonth(subMonths(currentDate, 1)),
        endDate: endOfMonth(subMonths(currentDate, 1)),
        pastStartDate: startOfMonth(subMonths(currentDate, 2)),
        pastEndDate: endOfMonth(subMonths(currentDate, 2)),
        key: 'Last Month'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 30 Days') {
      dateInfo = {
        startDate: subDays(new Date(), 30),
        endDate: subDays(new Date(), 1),
        pastStartDate: subDays(new Date(), 60),
        pastEndDate: subDays(new Date(), 31),
        key: 'Last 30 Days'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'This Year') {
      dateInfo = {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: addDays(new Date(), -1),
        pastStartDate: new Date(new Date().getFullYear() - 1, 0, 1),
        pastEndDate: new Date(new Date().getFullYear(), 0, 0),
        key: 'This Year'
      };

      console.log('dateInfo', { dateInfo });
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Days') {
      dateInfo = {
        startDate: startOfDay(subDays(currentDate, 7)),
        endDate: addDays(new Date(), -1),
        pastStartDate: startOfDay(subDays(currentDate, 14)),
        pastEndDate: endOfDay(subDays(currentDate, 8)),
        key: 'Last 7 Days'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Weeks') {
      dateInfo = {
        startDate: subWeeks(subDays(currentDate, 1), 7),
        endDate: subDays(currentDate, 1),
        pastStartDate: subDays(subWeeks(subDays(currentDate, 1), 14), 1),
        pastEndDate: subDays(subWeeks(subDays(currentDate, 1), 7), 1),
        key: 'Last 7 Weeks'
      };
      console.log('dateInfo', { dateInfo });
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Months') {
      dateInfo = {
        startDate: startOfMonth(subMonths(currentDate, 7)),
        endDate: endOfMonth(subMonths(currentDate, 1)),
        pastStartDate: startOfMonth(subMonths(currentDate, 14)),
        pastEndDate: endOfMonth(subMonths(currentDate, 8)),
        key: 'Last 7 Months'
      };
      console.log('dateInfo', { dateInfo });

      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Weekdays') {
      // Initialize counters
      let last7WeekdaysCount = 0;
      let beforeLast7WeekdaysCount = 0;

      // Arrays to store the date ranges
      const last7Weekdays = [];
      const beforeLast7Weekdays = [];

      // Iterate from the current date backwards to find the dates
      while (last7WeekdaysCount < 7 || beforeLast7WeekdaysCount < 7) {
        const currentDateCopy = new Date(currentDate);

        // Subtract a day from the current date
        currentDateCopy.setDate(currentDateCopy.getDate() - 1);

        // Check if the current date is a weekday (not a weekend)
        if (!isWeekend(currentDateCopy)) {
          if (last7WeekdaysCount < 7) {
            last7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            last7WeekdaysCount++;
          } else if (beforeLast7WeekdaysCount < 7) {
            beforeLast7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            beforeLast7WeekdaysCount++;
          }
        }

        // Move to the previous day
        currentDate.setDate(currentDate.getDate() - 1);
      }

      console.log('updateRangeView', { last7Weekdays, beforeLast7Weekdays });

      const last7Week = last7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: dateItem.start,
            endDate: dateItem.end,
            pastStartDate: dateItem.start,
            pastEndDate: dateItem.end,
            key: ''
          };
        });

      const beforeLast7Week = beforeLast7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: dateItem.start,
            endDate: dateItem.end,
            pastStartDate: dateItem.start,
            pastEndDate: dateItem.end,
            key: ''
          };
        });

      if (beforeLast7Week && last7Week) {
        const rangesSelected = beforeLast7Week.concat(last7Week);

        console.log('RANGES', rangesSelected);
        // doCompare ? rangesSelected : last7Week
        setSelectedDateRange(last7Week);

        dateInfo = {
          startDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          endDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[last7Weekdays.length - 1].end,
          pastStartDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          pastEndDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[beforeLast7Weekdays.length - 1]
            .end,
          dates: last7Week.map((row) => row.startDate),
          compareDates: beforeLast7Week.map((row) => row.startDate),
          key: 'Last 7 Weekdays',
          current: last7Week,
          previous: beforeLast7Week
        };

        setTempDateRange(dateInfo);
      }
    } else if (rangeInfo.key === 'Last 7 Weekends') {
      // Initialize counters
      let last7WeekdaysCount = 0;
      let beforeLast7WeekdaysCount = 0;

      // Arrays to store the date ranges
      const last7Weekdays = [];
      const beforeLast7Weekdays = [];

      // Iterate from the current date backwards to find the dates
      while (last7WeekdaysCount < 7 || beforeLast7WeekdaysCount < 7) {
        const currentDateCopy = new Date(currentDate);

        // Subtract a day from the current date
        currentDateCopy.setDate(currentDateCopy.getDate() - 1);

        // Check if the current date is a weekday (not a weekend)
        if (isWeekend(currentDateCopy)) {
          if (last7WeekdaysCount < 7) {
            last7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            last7WeekdaysCount++;
          } else if (beforeLast7WeekdaysCount < 7) {
            beforeLast7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            beforeLast7WeekdaysCount++;
          }
        }

        // Move to the previous day
        currentDate.setDate(currentDate.getDate() - 1);
      }

      console.log('updateRangeView', { last7Weekdays, beforeLast7Weekdays });

      const last7Week = last7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: dateItem.start,
            endDate: dateItem.end,
            pastStartDate: dateItem.start,
            pastEndDate: dateItem.end,
            key: ''
          };
        });

      const beforeLast7Week = beforeLast7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: dateItem.start,
            endDate: dateItem.end,
            pastStartDate: dateItem.start,
            pastEndDate: dateItem.end,
            key: ''
          };
        });

      if (beforeLast7Week && last7Week) {
        const rangesSelected = beforeLast7Week.concat(last7Week);

        console.log('RANGES', rangesSelected);
        // doCompare ? rangesSelected : last7Week
        setSelectedDateRange(last7Week);

        dateInfo = {
          startDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          endDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[last7Weekdays.length - 1].end,
          pastStartDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          pastEndDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[beforeLast7Weekdays.length - 1]
            .end,
          dates: last7Week.map((row) => row.startDate),
          compareDates: beforeLast7Week.map((row) => row.startDate),
          key: 'Last 7 Weekends',
          current: last7Week,
          previous: beforeLast7Week
        };

        setTempDateRange(dateInfo);
      }
    } else if (rangeInfo.key === 'Last 7 Same day') {
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const currentDayOfWeek = getDay(currentDate);

      // Initialize an array to store the date ranges for the last 7 Mondays from the last 7 weeks
      const last7SameDays = [];

      // Calculate the date ranges for the last 7 Mondays from the last 7 weeks
      for (let i = 0; i < 8; i++) {
        const targetDay = subWeeks(currentDate, i); // Subtract weeks for the same day of the week

        // Check if the day matches Monday
        if (getDay(targetDay) === currentDayOfWeek && !isSameDay(targetDay, currentDate)) {
          const targetDate = eachDayOfInterval({ start: targetDay, end: targetDay });
          last7SameDays.push({ start: targetDate[0], end: targetDate[0] });
        }
      }

      // Initialize an array to store the date ranges for the 7 Mondays before the last 7 Mondays
      const beforeLast7SameDays = [];

      // Calculate the date ranges for the 7 Mondays before the last 7 Mondays
      for (let i = 8; i < 15; i++) {
        const targetDay = subWeeks(currentDate, i); // Subtract weeks for the same day of the week

        // Check if the day matches Monday
        if (getDay(targetDay) === currentDayOfWeek && !isSameDay(targetDay, currentDate)) {
          const targetDate = eachDayOfInterval({ start: targetDay, end: targetDay });
          beforeLast7SameDays.push({ start: targetDate[0], end: targetDate[0] });
        }
      }

      if (last7SameDays && beforeLast7SameDays) {
        console.log({ last7SameDays, beforeLast7SameDays });

        const last7Week = last7SameDays
          .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
          .map((dateItem, index) => {
            return {
              startDate: dateItem.start,
              endDate: dateItem.end,
              pastStartDate: dateItem.start,
              pastEndDate: dateItem.end,
              key: 'Last 7 Same day'
            };
          });

        const beforeLast7Week = beforeLast7SameDays
          .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
          .map((dateItem, index) => {
            return {
              startDate: dateItem.start,
              endDate: dateItem.end,
              pastStartDate: dateItem.start,
              pastEndDate: dateItem.end,
              key: 'Last 7 Same day'
            };
          });

        if (beforeLast7Week && last7Week) {
          const rangesSelected = beforeLast7Week.concat(last7Week);

          console.log('RANGES', rangesSelected);
          // doCompare ? rangesSelected : last7Week
          setSelectedDateRange(last7Week);

          dateInfo = {
            startDate: last7SameDays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
            endDate: last7SameDays.sort((rowA, rowB) => Number(rowA.end) - Number(rowB.end))[last7SameDays.length - 1].end,
            pastStartDate: beforeLast7SameDays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
            pastEndDate: beforeLast7SameDays.sort((rowA, rowB) => Number(rowA.end) - Number(rowB.end))[beforeLast7SameDays.length - 1].end,
            dates: last7Week.map((row) => row.startDate),
            compareDates: beforeLast7Week.map((row) => row.startDate),
            key: 'Last 7 Same day',
            current: last7Week,
            previous: beforeLast7Week
          };

          setTempDateRange(dateInfo);

          console.log('RANGES', rangesSelected);

          // doCompare ? rangesSelected : last7Week
          setSelectedDateRange(last7Week);
        }
      }

      console.log({ last7SameDays, beforeLast7SameDays });
    } else if (rangeInfo.key === 'Custom') {
      function getPreviousPeriod(startDate, endDate) {
        // Calculate the duration between startDate and endDate
        const duration = eachDayOfInterval({ start: startDate, end: endDate });

        // Subtract the duration from the start and end dates to get the previous period
        const prevStartDate = sub(startDate, { days: duration.length });
        const prevEndDate = sub(endDate, { days: duration.length });

        return { prevStartDate, prevEndDate };
      }

      const { prevStartDate, prevEndDate } = getPreviousPeriod(rangeInfo.startDate, rangeInfo.endDate);
      console.log('previousInfo', { prevStartDate, prevEndDate });

      dateInfo = {
        startDate: rangeInfo.startDate,
        endDate: rangeInfo.endDate,
        pastStartDate: prevStartDate,
        pastEndDate: prevEndDate,
        key: ''
      };

      console.log('dateInfo', { dateInfo });

      setTempDateRange(dateInfo);

      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    }
  };

  const GetMetricsChatData = () => {
    doTrendChartApi.request({
      url: '/metrics-breakdown',
      payload: {
        ...payload,
        profile_id: String(localUserProfileId?.profileId),
        metric: selectedMetric.label,
        start_date: formatDate(selectedDate[0]),
        end_date: formatDate(selectedDate[1])
      }
    });
  };
  const getDropValue = (event) => {
    console.log('getDropValue', event);
    // setSelectedDropValue(rowData)
  };

  const [dateRanges, setDateRanges] = useState([]);
  const [doCompare, setDoCompare] = useState(1);

  useEffect(() => {
    const getDateRanges = ['Last 7 Days', 'Last 7 Months', 'Last 7 Weekdays', 'Last 7 Weekends'];

    let getDateRangeResponse = [];

    const getRanges = async () => {
      await getDateRanges.forEach(async (range) => {
        if (range === 'Last 7 Days') {
          const response = await getlast7WeeksRange();
          getDateRangeResponse.push(response);
        } else if (range === 'Last 7 Months') {
          const response = await getlast7MonthsRange();
          getDateRangeResponse.push(response);
        } else if (range === 'Last 7 Weekdays') {
          const response = await getLast7WeekDaysRange();
          getDateRangeResponse.push(response);
        } else if (range === 'Last 7 Weekends') {
          const response = await getlast7MonthsRange();
          getDateRangeResponse.push(response);
        }
        if (getDateRanges.length == getDateRangeResponse.length) {
          setDateRanges(getDateRangeResponse);
        }
      });
    };

    getRanges();

    return () => {
      getDateRangeResponse = [];
    };
  }, []);

  function dateFormat(dateString) {
    console.log('date', dateString);
    const date = new Date(dateString);
    console.log('date--', date);
    return date;
  }

  useEffect(() => {
    if (show === true) {
      GetMetricsChatData();
    }
  }, [show, selectedMetric.label]);

  useEffect(() => {
    const { data, loading } = doTrendChartApi;
    console.log('doTrendChartApi', data);

    if (!loading && data && data?.data) {
      setBreakDownMetrics({ categories: data.data.categories, series: data.data.series });
    } else if (!loading && data && data.success === false) {
      setBreakDownMetrics({ categories: [], series: [] });
      sweetAlertHandler({ type: 'alert', text: data.message ? data.message : 'Please select another mertic!!', showConfirmButton: true });
    }
  }, [doTrendChartApi.loading]);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      // text: '',
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action
        ? alert.action
        : () => {
            console.log('Actioned');
          },
      allowOutsideClick: false,
      showCancelButton: alert.CancelButton
    });
  };

  const [typeOfMetric, setTypeOfMetric] = useState('');
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [noColor, setNoColor] = useState(0);

  return (
    <Modal
      show={show}
      onHide={close}
      dialogClassName="modal-90w z-index-lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="f-18 f-w-700">{table_name}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-end mb-4 position-relative px-4">
          {/* <section className="border px-3 py-2 cursor-pointer f-14 rounded-8 f-w-700 text-dark mr-3" onClick={() => setfilteroption(!showfilteroption)}>
            <img src={FilterIcon} alt="filter" className="mr-2 wid-15" />
            Daily
          </section>

          {showfilteroption &&
            <div className="filter-chart filter-chart-height">
              <ul>
                <li className="d-flex align-items-center">
                  <Radiobutton id={1} radioname="chart-option" title="Daily" checked="true" />
                </li>
                <li className="d-flex align-items-center">
                  <Radiobutton id={2} radioname="chart-option" title="Weekly" checked="true" />
                </li>
                <li className="d-flex align-items-center">
                  <Radiobutton id={3} radioname="chart-option" title="Monthly" checked="true" />
                </li>
              </ul>
            </div>
          } */}
          <p className="f-14 text-dark"> Showing top 10 results of the </p> &nbsp; &nbsp;
          <section className="position-relative">
            <div
              className="bg-white border px-3 py-2 mr-3 text-dark d-flex align-items-center f-w-500 f-14 cursor-pointer rounded-8"
              onClick={() => setShowdatepicker(true)}
            >
              <img src={Calender} alt="date_picker" className="wid-15 mr-2" />
              {dateSelected.key != '' && dateSelected.key != 'selection' ? dateSelected.key : 'Custom'} :{' '}
              {format(dateSelected.startDate, 'dd MMM, yyyy')}{' '}
              {format(dateSelected.startDate, 'dd MMM, yyyy') !== format(dateSelected.endDate, 'dd MMM, yyyy')
                ? ` - ${format(dateSelected.endDate, 'dd MMM, yyyy')}`
                : ``}
              <CollapseArrowIcon />
            </div>
            {showDatepicker && <div className="position-fixed inset-0 z-1500" onClick={() => setShowdatepicker(false)}></div>}
            {showDatepicker && (
              <div className={`date-picker-modal ${selectedDateRange.length > 1 ? 'hide_range' : ''}`}>
                <DateRangePicker
                  moveRangeOnFirstSelection={false}
                  retainEndDateOnFirstSelection={false}
                  editableDateInputs={true}
                  onCalendarFocus={handleStaticRangeFocus}
                  onRangeFocus={handleDateClick}
                  ranges={selectedDateRange}
                  months={2}
                  onChange={(item) => {
                    console.log('CHANGE', item);
                    if (item.range1) {
                      console.log('item.range1', item);
                      setSelectedDateRange([
                        {
                          startDate: dateFormat(item.range1.startDate),
                          endDate: dateFormat(item.range1.endDate),
                          key: item.range1.key != '' && item.range1.key != 'selection' ? item.range1.key : 'Custom'
                        }
                      ]);
                      updateRangeView({
                        startDate: dateFormat(item.range1.startDate),
                        endDate: dateFormat(item.range1.endDate),
                        key: item.range1.key != '' && item.range1.key != 'selection' ? item.range1.key : 'Custom'
                      });
                    } else if (item.selection) {
                      setSelectedDateRange([
                        {
                          startDate: dateFormat(item.selection.startDate),
                          endDate: dateFormat(item.selection.endDate),
                          key: item.selection.key != '' && item.selection.key != 'selection' ? item.selection.key : 'Custom'
                        }
                      ]);
                      updateRangeView({
                        startDate: dateFormat(item.selection.startDate),
                        endDate: dateFormat(item.selection.endDate),
                        key: item.selection.key != '' && item.selection.key != 'selection' ? item.selection.key : 'Custom'
                      });
                    } else if (!item.range) {
                      console.log('!item.range', item);
                      console.log(Object.values(item)[0]);
                      // setSelectedDateRange([]);
                      updateRangeView(Object.values(item)[0]);
                    }
                  }}
                  direction="horizontal"
                  maxDate={addDays(new Date(), -1)}
                  staticRanges={createStaticRanges([
                    {
                      label: 'Yesterday',
                      range: () => ({
                        startDate: addDays(new Date(), -1),
                        endDate: addDays(new Date(), -1),
                        key: 'Yesterday'
                      })
                    },
                    {
                      label: 'This Month',
                      range: () => ({
                        startDate: startOfMonth(new Date()),
                        endDate: addDays(new Date(), -1) < startOfMonth(new Date()) ? startOfMonth(new Date()) : addDays(new Date(), -1),
                        key: 'This Month'
                      })
                    },
                    {
                      label: 'This Year',
                      range: () => ({
                        startDate: new Date(new Date().getFullYear(), 0, 1),
                        endDate: addDays(new Date(), -1),
                        key: 'This Year'
                      })
                    },
                    {
                      label: 'Last Month',
                      range: () => ({
                        startDate: startOfMonth(subMonths(new Date(), 1)),
                        endDate: endOfMonth(subMonths(new Date(), 1)),
                        key: 'Last Month'
                      })
                    },
                    {
                      label: 'Last 30 Days',
                      range: () => ({
                        startDate: subDays(new Date(), 30, -1),
                        endDate: addDays(new Date(), -1),
                        key: 'Last 30 Days'
                      })
                    },
                    {
                      label: 'Last 7 Days',
                      range: () => ({
                        startDate: subDays(new Date(), 7, -1),
                        endDate: addDays(new Date(), -1),
                        key: 'Last 7 Days'
                      })
                    },
                    // {
                    //   label: 'Life Time',
                    //   range: () => ({
                    //     startDate: new Date(2013, 4, 5),
                    //     endDate: addDays(new Date(), -1),
                    //     key: 'Life Time'
                    //   })
                    // },
                    {
                      label: 'Last 7 Weeks',
                      range: () => ({
                        startDate: new Date(dateRanges[0][0]),
                        endDate: new Date(dateRanges[0][1]),
                        key: 'Last 7 Weeks'
                      })
                    },
                    {
                      label: 'Last 7 Months',
                      range: () => ({
                        startDate: new Date(dateRanges[1][0]),
                        endDate: addDays(new Date(), -1),
                        key: 'Last 7 Months'
                      })
                    },
                    {
                      label: 'Last 7 Weekdays',
                      range: () => ({
                        startDate: new Date(dateRanges[2][0]),
                        endDate: addDays(new Date(), -1),
                        key: 'Last 7 Weekdays'
                      })
                    },
                    {
                      label: 'Last 7 Weekends',
                      range: () => ({
                        startDate: new Date(dateRanges[3][0]),
                        endDate: addDays(new Date(), -1),
                        key: 'Last 7 Weekends'
                      })
                    },
                    {
                      label: 'Last 7 Same day',
                      range: () => ({
                        key: 'Last 7 Same day'
                      })
                    }
                  ])}
                />
                <div className="dat-pic-close bg-white d-flex flex-column px-0">
                  <section className="d-flex align-items-center justify-content-between mb-2 w-100 pb-2 border-bottom px-3">
                    <div className="d-flex align-items-start">
                      {doCompare ? (
                        <span className="f-12">
                          <b className="f-w-800 txt-skyblue">{tempDateRange.key != '' ? 'Previous' : 'Previous'}</b> :{' '}
                          {isSameDay(tempDateRange.pastStartDate, tempDateRange.pastEndDate)
                            ? format(tempDateRange.pastStartDate, 'dd MMM, yy')
                            : format(tempDateRange.pastStartDate, 'dd MMM, yy') + ' - ' + format(tempDateRange.pastEndDate, 'dd MMM, yy')}
                        </span>
                      ) : null}{' '}
                      &nbsp; <br />
                      <span className="mr-2 f-12">
                        <b className="f-w-800 txt-violet">{tempDateRange.key != '' ? 'Current' : 'Current'}</b> :{' '}
                        {isSameDay(tempDateRange.startDate, tempDateRange.endDate)
                          ? format(tempDateRange.startDate, 'dd MMM, yy')
                          : format(tempDateRange.startDate, 'dd MMM, yy') + ' - ' + format(tempDateRange.endDate, 'dd MMM, yy')}
                      </span>
                    </div>
                    <Form className="ml-auto">
                      <Form.Check
                        type="switch"
                        id="compare"
                        label="Compare"
                        onChange={(event) => {
                          if (event.target.checked === true) {
                            setDoCompare(1);
                          } else if (event.target.checked === false) {
                            setDoCompare(0);
                          }
                        }}
                        checked={doCompare === 1}
                        className="f-13"
                      />
                    </Form>
                  </section>
                  <section className="d-flex align-items-center justify-content-end w-100 px-3">
                    <p
                      className="secondary-btn text-light f-13 py-2 px-3 mr-2 rounded-8 cursor-pointer"
                      onClick={() => {
                        setShowdatepicker(false);
                      }}
                    >
                      Close
                    </p>
                    <p
                      className="primary-btn text-light f-13 py-2 px-3 rounded-8 m-0 cursor-pointer"
                      onClick={() => {
                        saveDateRange();
                      }}
                    >
                      Submit
                    </p>
                  </section>
                </div>
              </div>
            )}
          </section>
          <section className="border px-3 py-2 cursor-pointer f-14 rounded-8 f-w-700 text-dark" onClick={() => setShowoption(!showOption)}>
            <img src={theme.theme === 'DARK' ? FilterLight : FilterIcon} alt="filter" className="mr-2 wid-15" />
            {selectedMetric.label}
          </section>
          {showOption && (
            <div className="filter-chart">
              <ul>
                {chartDropDownValue.map((options, index) => (
                  <li
                    className="d-flex align-items-center"
                    key={'radio_' + index}
                    onClick={() => {
                      setSelectedMetric(options);
                      setShowoption(!showOption);
                    }}
                  >
                    <Radiobutton
                      id={index}
                      radioname="chart-option"
                      title={options.label}
                      checked={selectedMetric.label == options.label}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {doTrendChartApi.loading && (
          <div className="d-flex align-items-center justify-content-center position-absolute chart-load f-w-700 text-dark bg-overlay">
            <Loader /> Loading...
          </div>
        )}

        {breakDownMetrics.categories.length === 0 && breakDownMetrics.series.length === 0 && (
          <div className="table-emptymsg"> No Data Found</div>
        )}

        <MetricsChart
          typeOfMetric={typeOfMetric}
          selectedSeries={selectedSeries}
          noColor={noColor}
          singleYAxis={1}
          options={breakDownMetrics}
          optionalMetric={selectedMetric}
        />
      </Modal.Body>
    </Modal>
  );
}


export default Users;
