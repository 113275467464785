const authToken = JSON.parse(localStorage.getItem('User'));

export const BASENAME = '/'; // don't add '/' at end off BASENAME
export const BASE_URL = authToken && authToken?.profileStatus == true ? '/amazon/targeting' : authToken?.flipkartProfileStatus == true ? '/summary/flipkart' : authToken?.myntraProfileStatus == true ? '/summary/myntra' : '/addaccount';
export const MAIN_URL = authToken && authToken?.profileStatus == true ? '/amazon/targeting' : authToken?.flipkartProfileStatus == true ? '/summary/flipkart' : authToken?.myntraProfileStatus == true ? '/summary/myntra' : '/addaccount'
export const BASE_TITLE = 'Hector';

export const CONFIG = {
  layout: 'vertical', // vertical, horizontal
  subLayout: '', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: true, // mini-menu
  layoutType: 'menu-dark', // menu-dark, menu-light, dark
  navIconColor: false,
  headerBackColor: 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true, // only for vertical layouts
  headerFixedLayout: false, // only for vertical layouts
  boxLayout: false,
  navDropdownIcon: 'style1', // style1, style2, style3
  navListIcon: 'style1', // style1, style2, style3, style4, style5, style6
  navActiveListColor: 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: true,
  layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
  layout6BackSize: '', // 'auto' - for background pattern, 'cover' - for background images & used only for pre-layout = layout-6
  firebase: {
  }
};


export const MARKET_PLACE = {
  'north_america':'https://www.amazon.com/ap/oa', 
  'europe' : 'https://eu.account.amazon.com/ap/oa', 
  'east':'https://apac.account.amazon.com/ap/oa', 
  'sandbox':'https://www.amazon.com/ap/oa'
}

export const COUNTRIES = [
  {
      "country_name": "Brazil",
      "country_code": "BR",
      "region_name": "north_america",
      "url_prefix": "https://www.amazon.com/ap/oa",
      "currency_code": "BRL"
  },
  {
      "country_name": "Canada",
      "country_code": "CA",
      "region_name": "north_america",
      "url_prefix": "https://www.amazon.com/ap/oa",
      "currency_code": "CAD"
  },
  {
      "country_name": "Mexico",
      "country_code": "MX",
      "region_name": "north_america",
      "url_prefix": "https://www.amazon.com/ap/oa",
      "currency_code": "MXN"
  },
  {
      "country_name": "US",
      "country_code": "US",
      "region_name": "north_america",
      "url_prefix": "https://www.amazon.com/ap/oa",
      "currency_code": "USD"
  },
  {
      "country_name": "United Arab Emirates",
      "country_code": "AE",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "AED"
  },
  {
      "country_name": "Germany",
      "country_code": "DE",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "EUR"
  },
  {
      "country_name": "Egypt",
      "country_code": "EG",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "EGP"
  },
  {
      "country_name": "Spain",
      "country_code": "ES",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "EUR"
  },
  {
      "country_name": "France",
      "country_code": "FR",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "EUR"
  },
  {
      "country_name": "Belguim",
      "country_code": "BE",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "EUR"
  },
  {
      "country_name": "UK",
      "country_code": "UK",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "GBP"
  },
  {
      "country_name": "India",
      "country_code": "IN",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "INR"
  },
  {
      "country_name": "Italy",
      "country_code": "IT",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa"
  },
  {
      "country_name": "Netherlands",
      "country_code": "NL",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "EUR"
  },
  {
      "country_name": "Poland",
      "country_code": "PL",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "PLN"
  },
  {
      "country_name": "Saudi Arabia",
      "country_code": "SA",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "SAR"
  },
  {
      "country_name": "Sweden",
      "country_code": "SE",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "SEK"
  },
  {
      "country_name": "Turkey",
      "country_code": "TR",
      "region_name": "europe",
      "url_prefix": "https://eu.account.amazon.com/ap/oa",
      "currency_code": "TRY"
  },
  {
      "country_name": "Singapore",
      "country_code": "SG",
      "region_name": "east",
      "url_prefix": "https://apac.account.amazon.com/ap/oa",
      "currency_code": "SGD"
  },
  {
      "country_name": "Australia",
      "country_code": "AU",
      "region_name": "east",
      "url_prefix": "https://apac.account.amazon.com/ap/oa",
      "currency_code": "AUD"
  },
  {
      "country_name": "Japan",
      "country_code": "JP",
      "region_name": "east",
      "url_prefix": "https://apac.account.amazon.com/ap/oa",
      "currency_code": "JPY"
  }
]