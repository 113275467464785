import React, { useState, useEffect, useRef } from 'react'
import styles from './styles.module.css'
import useApi from 'src/hooks/useApi'
import services from 'src/api/service'
import { CaretDownIcon } from './Caret-Down'
import { COUNTRIES } from 'src/config/constant'

export const CustomCountryDropdown = (props) => {
  const [countries, setCountries] = useState(COUNTRIES)
  const [countriesCopy, setCountriesCopy] = useState(COUNTRIES)
  const [open, setOpen] = useState(false)
  const [defaultCountry, setDefaultCountry] = useState({})

  const getCountries = useApi(services.getService)

  const dropdownRef = useRef(null)

  useEffect(() => {
    defaultCountrySetter(props.countryCode ? COUNTRIES.find((country_row) => (country_row.country_code === props.countryCode)) : {
        "country_name": "US",
        "country_code": "US",
        "region_name": "north_america",
        "url_prefix": "https://www.amazon.com/ap/oa",
        "currencyCode": "USD"
    })
    document.addEventListener('mousedown', handleClickOutSide)
  }, [])

  const defaultCountrySetter = (dResponse) => {
    setDefaultCountry(dResponse)
  }

  const preFetchCountries = async () => {
    const data = await fetch('https://restcountries.com/v2/all')
    const result = await data.json()
    return result
  }

  const handleClickOutSide = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const toggleDropDown = () => {
    /* reset all countries before closing */
    if (!open) {
      setCountries(countriesCopy)
    }
    setOpen(!open)
  }

  const handleCountryClick = (country) => {
    const result = {...country, code: country.country_code}
    setDefaultCountry(result)

    if (props.onSelect) {
      props.onSelect(result)
    }

    /* Hide the dropdown menu on selecting a country */
    toggleDropDown()
  }

  const handleSearchInput = (e) => {
    const input = e.target.value.toLowerCase()
    let filteredCountries = countriesCopy.filter((i) =>
      i.country_name.toLowerCase().includes(input.toLowerCase())
    )
    setCountries(filteredCountries)
  }

  return (
    <div className={`${styles.container} drop-down-container`} ref={dropdownRef}>
      <div className={`${styles.dropdown} drop-down-form-field`} onClick={toggleDropDown}>
        <img
          className={styles.country_flag}
          src={defaultCountry?.country_code?.toLowerCase() === 'uk' ? 'https://flagpedia.net/data/flags/w1160/gb.webp' : `https://flagcdn.com/${defaultCountry?.country_code?.toLowerCase()}.svg`}
          alt={defaultCountry?.country_name}
        />
        <span className={`${styles.selected_country} custom-select-text`}>
          {props.showFullName ? defaultCountry?.country_name : defaultCountry?.country_code}
        </span>
        <CaretDownIcon point={open ? 'up' : 'down'} />
      </div>

      {open && (
        <div className={`${styles.dropdown_items_wrapper} custom-wrapper-select`}>
          <CaretDownIcon point='up_white' />
          <div className={styles.input_wrapper}>
            <input
              onChange={(e) => handleSearchInput(e)}
              className={styles.country_search}
              type='text'
              placeholder='search coutries...'
            />
          </div>

          <div className={styles.dropdown_items}>
            {countries.map((i, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleCountryClick(i)}
                  className={styles.dropdown_item}>
                  <img className={styles.country_flag} src={i.country_code.toLowerCase() === 'uk' ? 'https://flagpedia.net/data/flags/w1160/gb.webp' : `https://flagcdn.com/${i.country_code.toLowerCase()}.svg`} alt='' />
                  <span className={styles.dropdown_item_title}> {i.country_name}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}