export const reviseStringCode = {
  Campus: 'Tampa',
  campus: 'tampa',
  CAMPUS: 'TAMPA',
};

export const reReviseStringCode = {
  Tampa: 'Campus',
  tampa: 'campus',
  TAMPA: 'CAMPUS'
};

export function replaceString(inputString = '') {
  const authToken = JSON.parse(localStorage.getItem('User')) || {};
  if (process.env.REACT_APP_DEMO_EMAIL.indexOf(authToken?.email) > -1) {
    // Iterate over each key-value pair in reviseStringCode
    for (const [original, replacement] of Object.entries(reviseStringCode)) {
      // Construct a regex pattern with the 'g' flag to replace all occurrences
      const regex = new RegExp(original, 'g');
      // Replace occurrences of the original string with the replacement string
      inputString = inputString.replace(regex, replacement);
    }
  }
  return inputString;
}

export function reverseReplaceString(inputString = '') {
  const authToken = JSON.parse(localStorage.getItem('User')) || {};
  if (process.env.REACT_APP_DEMO_EMAIL.indexOf(authToken?.email) > -1) {
    // Iterate over each key-value pair in reReviseStringCode
    for (const [replacement, original] of Object.entries(reReviseStringCode)) {
      // Construct a regex pattern with the 'g' flag to replace all occurrences
      const regex = new RegExp(replacement, 'g');
      // Replace occurrences of the replacement string with the original string
      inputString = inputString.replace(regex, original);
    }
  }
  return inputString;
}

export function getRandomAsin() {
  // Static prefix
  const prefix = "B0";
  // Generate random alphanumeric characters (uppercase only)
  const randomString = Math.random().toString(36).substring(2, 10).toUpperCase();
  // Combine prefix and random string
  return prefix + randomString;
}


export function getRandomFSN() {
  // Static prefix
  const prefix = "SHOGTWF";
  // Generate random alphanumeric characters (uppercase only)
  const randomString = Math.random().toString(36).substring(2, 10).toUpperCase();
  // Combine prefix and random string
  return `${prefix}${randomString}`;
}