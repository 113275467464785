import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import TickIcon from 'src/assets/images/common/white-tick.svg';
import Brands from './brands';
import AdAccount from './adAccount';
import Seller from './seller';
import Vendor from './vendor';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';
import SpinnerIcon from 'src/assets/icons/SpinnerIcon';

const tabData = window.location.href.indexOf('/amazon/targeting') > -1 ? [
  { eventKey: 'ad_account', title: 'Ad Account' },
] : [
  { eventKey: 'brands', title: 'Brands' },
  { eventKey: 'ad_account', title: 'Ad Account' },
  { eventKey: 'seller', title: 'Seller' },
  { eventKey: 'vendor', title: 'Vendor' }
];

export default function Tabs({ closePopover, doFetchBrandsAccounts, listFilters, saveMasterFilters, updateSelectedItems, handleSubmit, updateInCludeSBSpends, includeSBSpends, setIncludeSBSpends }) {

  const [activeKey, setActiveKey] = useState(tabData[0].eventKey);

  return (
    <React.Fragment key={doFetchBrandsAccounts.loading + listFilters?.length}>
      <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        {doFetchBrandsAccounts.loading ? <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1'
          }}
        >
          <SpinnerIcon width={30} height={30} style={{ color: '#1877f2' }} />
        </div> : null}

        <Row className={`mx-0 ${doFetchBrandsAccounts.loading ? 'disabled' : ''}`}>
          <Col className="px-0" style={{maxWidth: "100px", ...(tabData.length == 1 ? { display: 'none'} : {})}}>
            <Nav variant="pills" className="flex-column">
              {tabData.map((tab) => (
                <Nav.Item key={tab.eventKey}>
                  <Nav.Link eventKey={tab.eventKey} active={tab.eventKey === activeKey}>
                    {tab.eventKey === activeKey && <img src={TickIcon} alt="selected-tab" className="wid-15" />}
                    {tab.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col>
            <Tab.Content>
              <Tab.Pane eventKey="brands">
                <Brands level="brands" list={listFilters?.brands} selectedItems={saveMasterFilters} updateSelectedItems={updateSelectedItems}             updateInCludeSBSpends={updateInCludeSBSpends} includeSBSpends={includeSBSpends} setIncludeSBSpends={setIncludeSBSpends} />
              </Tab.Pane>
              <Tab.Pane eventKey="ad_account">
                <AdAccount level="ams" list={listFilters?.ams} selectedItems={saveMasterFilters} updateSelectedItems={updateSelectedItems} />
              </Tab.Pane>
              <Tab.Pane eventKey="seller">
                <Seller level="seller" list={listFilters?.seller} selectedItems={saveMasterFilters} updateSelectedItems={updateSelectedItems} />
              </Tab.Pane>
              <Tab.Pane eventKey="vendor">
                <Vendor level="vendor" list={listFilters?.vendor} selectedItems={saveMasterFilters} updateSelectedItems={updateSelectedItems} />
              </Tab.Pane>
            </Tab.Content>
            <section className="d-flex align-items-center justify-content-end py-2 bg-white">
              <p className="cls-btn cursor-pointer rounded-4 f-10 py-2 px-3 f-w-600 f-202226" onClick={closePopover}>
                Cancel
              </p>
              <p className="bg-blue rounded-4 f-10 py-2 px-3 f-w-600 cursor-pointer text-white mt-0 ml-2" onClick={handleSubmit}>Apply</p>
            </section>
          </Col>
        </Row>
      </Tab.Container>
    </React.Fragment>
  );
}
