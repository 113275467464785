import * as CryptoJS from 'crypto-js';
import { reverseReplaceString } from './demo';

export const getUserIdentity = (path = '') => {
    const authUser = JSON.parse(localStorage.getItem('User'))
    const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
    const getProfileIds = JSON.parse(localStorage.getItem('active-profiles-ids'))
    const isFlipkart = window.location.href.indexOf('/flipkart') > -1
    const isMyntra = window.location.href.indexOf('/myntra') > -1
    const isBlinkit = window.location.href.indexOf('/blinkit') > -1
    const isAmazon = window.location.href.indexOf('/amazon') > -1
    const flipkartProfile = JSON.parse(localStorage.getItem('flipkartProfile'))
    const myntraProfile = JSON.parse(localStorage.getItem('myntraProfile'))
    const blinkitProfile = JSON.parse(localStorage.getItem('blinkitProfile'))

    const globalProfile = isFlipkart ? flipkartProfile : isAmazon ? getProfile : isMyntra ? myntraProfile : isBlinkit ? blinkitProfile : {}

    const encryptionObject = {
      email: authUser?.email,
      role: globalProfile ?  globalProfile.accessLevel : 'Admin',
      profileId: globalProfile ? globalProfile.profileId : null,
      region: getProfile && getProfile.marketplace ? getProfile.marketplace : null,
      datasetId: getProfile && getProfile.datasetId ? getProfile.datasetId : null,
      ...(getProfile && 'client_email' in getProfile && getProfile.client_email ? { client_email: getProfile.client_email } : {
        ...getProfile && 'clientEmail' in getProfile && getProfile.clientEmail ? { client_email: getProfile.clientEmail } : {}
      }),
      profile: [],
      // ...(getProfileIds ? { profile: getProfileIds} : { profile: []}),
      // ...(authUser && authUser?.email == process.env.REACT_APP_ADMIN_ACCESS_EMAIL ? { profile: [] } : {}),
      ...(isFlipkart ? { client: flipkartProfile?.Value } : isMyntra ? { client: myntraProfile?.Value } : isBlinkit ? { client: blinkitProfile?.Value } : {} ),
      ownerEmail: globalProfile && globalProfile?.profileOwner ? globalProfile?.profileOwner : authUser?.email,
      countryCode: globalProfile?.countryCode,
      currencyCode: globalProfile?.currencyCode,
      ...(getProfile && 'accountInfo' in getProfile &&  getProfile.accountInfo && globalProfile?.accountInfo?.marketplaceStringId ? { marketPlaceId: globalProfile?.accountInfo?.marketplaceStringId } : {}),
      ...(isAmazon && getProfile && 'accountInfo' in getProfile &&  getProfile.accountInfo ? { client: `${String(getProfile.accountInfo.name).replace(/ /g,'').toLocaleLowerCase()}-${String(getProfile.accountInfo.type).toLocaleLowerCase()}` } : {}),
      ...(getProfile && getProfile?.marketingStream && Object.keys(getProfile?.marketingStream).length > 0 && window.location.href.indexOf('amazon-dayparting') > -1 ? { marketingStream: getProfile?.marketingStream } : {}),
      ...(globalProfile?.ams?.groupedAccounts && globalProfile?.ams?.groupedAccounts?.length > 0 ? { groupedProfileIds: globalProfile?.ams?.groupedAccounts?.map((item) => item.profileId)} : {}),
    }

    let newPayload = encryptionObject

    if(process.env.REACT_APP_DEMO_EMAIL && process.env.REACT_APP_DEMO_EMAIL.indexOf(authUser?.email) > -1) {
      const jsonString = JSON.stringify(newPayload);
      const newString = reverseReplaceString(jsonString)
      newPayload = JSON.parse(newString)   
    }

    return dataEncrypt(newPayload)
}

export const dataEncrypt = (DATA) => {
  return CryptoJS.AES.encrypt(JSON.stringify(DATA), process.env.REACT_APP_CRYPTO_KEY).toString()
};

export const dataDecrypt = (DATA) => {
  return CryptoJS.AES.decrypt(decodeURIComponent(DATA), process.env.REACT_APP_CRYPTO_KEY);
};

export const dataStringEncrypt = (DATA) => {
  return CryptoJS.AES.encrypt(DATA, process.env.REACT_APP_CRYPTO_KEY).toString()
};

export const dataStringDecrypt = (DATA) => {
  return CryptoJS.AES.decrypt(decodeURIComponent(DATA), process.env.REACT_APP_CRYPTO_KEY);
};



export const loadScript = (src) => {
  return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
  });
}
