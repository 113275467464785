import { startOfMonth, subMonths, endOfMonth, addDays, startOfDay, subDays, endOfDay } from 'date-fns';
import React, { createContext, useReducer, useContext, useEffect } from 'react';

// Initial state for the context

const initialState = {
  dateRange: window.location.href.indexOf('flipkart-snapshot') > -1 ? {
    startDate: startOfDay(subDays(new Date(), 7)),
    endDate: addDays(new Date(), -1),
    pastStartDate: startOfDay(subDays(new Date(), 14)),
    pastEndDate: endOfDay(subDays(new Date(), 8)),
    key: 'Last 7 Days',
    compare: 1
  } : window.location.href.indexOf('snapshot') > -1 ? {
    startDate: addDays(new Date(), -1),
    endDate: addDays(new Date(), -1),
    pastStartDate: addDays(new Date(), -2),
    pastEndDate: addDays(new Date(), -2),
    key: 'Yesterday',
    compare: 1
  } :  window.location.href.indexOf('master-overview') > -1 || window.location.href.indexOf('sov') > -1 ? {
    startDate: startOfDay(subDays(new Date(), 7)),
    endDate: addDays(new Date(), -1),
    pastStartDate: startOfDay(subDays(new Date(), 14)),
    pastEndDate: endOfDay(subDays(new Date(), 8)),
    key: 'Last 7 Days',
    compare: 1
  } :  window.location.href.indexOf('/amazon-dayparting-scheduler') > -1 || window.location.href.indexOf('/amazon-dayparting-performance') > -1 ? {
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 1),
    pastStartDate: subDays(new Date(), 60),
    pastEndDate: subDays(new Date(), 31),
    key: 'Last 30 Days',
    compare: 1
  }: {
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 1),
    pastStartDate: subDays(new Date(), 60),
    pastEndDate: subDays(new Date(), 31),
    key: 'Last 30 Days'
  },
  crossFilters: [],
  availableColumns: [],
  availableTables: {},
  clearCrossFilters: false,
  applyCrossFilters: false
}

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DATE_RANGE':
      return { ...state, dateRange: action.payload };
    case 'UPDATE_SOV_FILTERS':
      return { ...state, filters: action.payload };
    case 'UPDATE_CROSS_FILTERS':
      return { ...state, crossFilters: action.payload };
    case 'CLEAR_CROSS_FILTERS':
      return { ...state, clearCrossFilters: action.payload };
    case 'APPLY_CROSS_FILTERS':
      return { ...state, applyCrossFilters: action.payload };
    case 'UPDATE_AVAILABLE_COLUMNS':
      return { ...state, availableColumns: action.payload };
    case 'UPDATE_AVAILABLE_TABLES':
      return { ...state, availableTables: action.payload };
    default:
      return state;
  }
};

// Create the context
const PayloadContext = createContext();

// Create a custom hook to use the context
const usePayloadContext = () => {
  const context = useContext(PayloadContext);
  if (!context) {
    throw new Error('usePayloadContext must be used within a PayloadProvider');
  }
  return context;
};

// Create the provider component
const PayloadProvider = ({ children }) => {

  const [payloadState, payloadDispatch] = useReducer(reducer, initialState);
  
  return (
    <PayloadContext.Provider value={{ payloadState, payloadDispatch }}>
      {children}
    </PayloadContext.Provider>
  );
};

export { PayloadProvider, usePayloadContext };