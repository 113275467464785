import React from 'react';
import { Redirect } from 'react-router-dom';
import { BASE_URL } from '../../config/constant';
import { MAIN_URL } from '../../config/constant';
import useAuth from '../../hooks/useAuth';
import { useCommonContext } from 'src/contexts/CommonContext';

const GuestGuard = ({ children }) => {
  const ParseData = JSON.parse(localStorage.getItem('User'));
  const { isLoggedIn } = useAuth();
  const { commonState } = useCommonContext()
  if (isLoggedIn) {
    if (ParseData && ParseData?.profileStatus === true) {
      const mainUrl = ParseData && ParseData?.profileStatus == true ? '/amazon/targeting' : ParseData?.flipkartProfileStatus == true ? '/summary/flipkart' : ParseData?.myntraProfileStatus == true ? '/summary/myntra' : '/addaccount'
      return <Redirect to={mainUrl} />;
    } else {
    return (
      <Redirect
        to={BASE_URL}
      />
    );
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
