import { useEffect, useState } from 'react';
import { useAmzContext } from 'src/contexts/AmzContext';
import { useFkContext } from 'src/contexts/FkContext';
import { useCommonContext } from 'src/contexts/CommonContext';
import { ReactComponent as SnapshotIcon } from 'src/assets/images/common/snapshot-white.svg';
import { ReactComponent as AmazonIcon } from 'src/assets/images/common/amazon-white.svg';
import { ReactComponent as MasterOverviewIcon } from 'src/assets/images/common/master-overview.svg';
import { ReactComponent as DaypartIconIcon } from 'src/assets/images/common/daypart.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/images/common/ams-dash.svg';
import { ReactComponent as HectorGptIcon } from 'src/assets/images/common/chat.svg';
//import { ReactComponent as DspIcon } from 'src/assets/images/dsp/digital-transformation.svg';
import { ReactComponent as AmcDashboardIcon } from 'src/assets/images/common/dashboard.svg';
import { ALLOWED_PROFILE, CHATGPT_PROFILES } from 'src/views/pages/audience-report/utils';
import newMenuItems from 'src/new-menu-items';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';

const useNewMenuItems = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [profile, setProfile] = useState(false)
  const { commonState } = useCommonContext();
  const { amzState } = useAmzContext();
  const { fkState } = useFkContext();
  const authToken = JSON.parse(localStorage.getItem('User'));
  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
  const profileIdCheck = useApi(services.postService);

  useEffect(() => {
    profileIdCheck.request({
      url: '/amc/amcOnboardedusers',
      nodeAPI: 1,
      payload: {}
    });
  }, []);

  useEffect(() => {
    const { loading, data } = profileIdCheck;
    if (!loading && data && data.success == true) {
      setProfile(data.data);
    }
  }, [profileIdCheck.loading]);

  useEffect(() => {
    const isSuperAdmin = process.env.REACT_APP_ADMIN_ACCESS_EMAIL.indexOf(authToken?.email) > -1 || false;
    const {
      flipkartProfileStatus: flipkartState = false,
      profileStatus: amazonState = false,
      myntraProfileStatus: myntraState = false,
      blinkitProfileStatus: blinkitState = false
    } = commonState?.userInfo || {};

    const clientMenus = [];
    let menuData = [];

    // Amazon Menu Configuration
    if (amzState) {
      const amazonMenu = {
        title: 'Amazon',
        icon: <AmazonIcon />,
        items: [
          { label: 'Targeting 360', icon: <DashboardIcon />, path: '/amazon/targeting' },
          { label: 'Snapshot', icon: <SnapshotIcon />, path: '/amazon-snapshot' },
          { label: 'Master Overview', icon: <MasterOverviewIcon />, path: '/amazon-master-overview' },
          { label: 'Dayparting', icon: <DaypartIconIcon />, path: '/amazon-dayparting-performance' },
          // { label: 'DSP', icon: <DspIcon />, path: '/amazon-dsp' },
        ],
      };

      // Push "Hector GPT" Menu to Amazon Items if Condition is Met
      if (CHATGPT_PROFILES.indexOf(String(getProfile?.profileId)) > -1) {
        amazonMenu.items.push({
          label: 'Hector GPT',
          icon: <HectorGptIcon />,
          path: '/amazon-chatgpt',
        });
      }

      if (amzState && ALLOWED_PROFILE.indexOf(String(getProfile?.profileId)) > -1) {
        amazonMenu.items.push({
          label: 'AMC Console',
          icon: <AmcDashboardIcon />,
          path: '/amazon-audience-report',
        });
      }

      clientMenus.push(amazonMenu);
    }

    // Super Admin Condition
    if (isSuperAdmin) {
      menuData = [
        ...clientMenus,
        newMenuItems[1],
        myntraState && newMenuItems[2],
        blinkitState && newMenuItems[3],
        newMenuItems[4]
      ].filter(Boolean);
    } else if (flipkartState && !amazonState) {
      menuData = [
        newMenuItems[1],
        myntraState && newMenuItems[2],
        blinkitState && newMenuItems[3],
      ].filter(Boolean);
    } else if (flipkartState && amazonState) {
      menuData = [
        ...clientMenus,
        newMenuItems[1],
        myntraState && newMenuItems[2],
        blinkitState && newMenuItems[3],
      ].filter(Boolean);
    } else if (amazonState) {
      menuData = clientMenus;

      if (myntraState) {
        menuData.push(
          newMenuItems[2]
        );
      }
      if (blinkitState) {
        menuData.push(
          newMenuItems[3]
        );
      }
    } else if (myntraState) {
      menuData = [
        newMenuItems[2]
      ];
    } else if (blinkitState) {
      menuData = [
        newMenuItems[3]
      ];
    }

    setMenuItems(menuData);

    return () => {
      setMenuItems([]);
    };
  }, [amzState.amazonProfiles, commonState]);

  return { menuItems };
};

export default useNewMenuItems;
