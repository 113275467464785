import React from 'react';

const Input = ({ placeholders, types, values, classes, onChangeCallback = () => {}, action, readonly = 0, onPasteCallback = () => {}, onlyNumber = false, min, onKeyPress = () => {}, ...rest}) => {
  if(readonly == 1) {
    return (
      <React.Fragment>
        <input
          onClick={action}
          type={types}
          placeholder={placeholders}
          value={values}
          className={`form-field ${classes}`}
          onChange={onChangeCallback}
          readOnly={1}
          {...rest}
        />
      </React.Fragment>
    );
  }

  if(types == 'number') {

    const handleKeyDown = (event) => {
      // Prevent the default behavior if the pressed key is 'e'
      if (event.key === 'e') {
        event.preventDefault();
      }
    };

    return (
      <React.Fragment>
        <input
          type={types}
          placeholder={placeholders}
          value={values}
          className={`form-field ${classes}`}
          onChange={onChangeCallback}
          onKeyDown={handleKeyDown}
          min={min}
          {...rest}
        />
      </React.Fragment>
    );
  }


  return (
    <React.Fragment>
      <input
        onClick={action}
        type={types}
        placeholder={placeholders}
        value={values}
        className={`form-field ${classes}`}
        onChange={onChangeCallback}
        onKeyPress={onKeyPress}
        onPaste={onPasteCallback}
        {...rest}
      />
    </React.Fragment>
  );
};

export default Input;
