import React, { useEffect, useState } from 'react'
import CheckBox from 'src/components/Form/checkbox';

export default function Seller({list = [], level, selectedItems = [], updateSelectedItems}) {
  const [selectedList, setSelectedList] = useState(selectedItems?.find((item) => item.level == level)?.keywords || [])
  useEffect(() => {
      setSelectedList(selectedItems?.find((item) => item.level == level)?.keywords || [])
  }, [selectedItems])

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
        const allSellerValues = list.map(seller => seller.value);
        setSelectedList(allSellerValues);
        const updatedSelectedItems = [...selectedItems];
        const levelIndex = updatedSelectedItems.findIndex(item => item.level === level);
        if (levelIndex !== -1) {
            updatedSelectedItems[levelIndex].keywords = allSellerValues;
        } else {
            updatedSelectedItems.push({ level: level, keywords: allSellerValues });
        }
        updateSelectedItems(updatedSelectedItems);
    } else {
        setSelectedList([]);
        const updatedSelectedItems = [...selectedItems];
        const levelIndex = updatedSelectedItems.findIndex(item => item.level === level);
        if (levelIndex !== -1) {
            updatedSelectedItems[levelIndex].keywords = [];
            updateSelectedItems(updatedSelectedItems);
        }
    }
};


  return (
    <React.Fragment>
            <h2 className='f-12 f-w-500 mb-2 f-101828'>Seller </h2>

            {list?.length > 0 ? <div className='mt-3' style={{paddingLeft: '2px'}}>
              <CheckBox id="select_all_seller" label="Select All" classes="f-12 f-w-500 check-lg f-101828" checked={selectedList.length === list.length} action={handleSelectAll} />
            </div> : null}

            <ul className='global-filter-tabs-list-items'>
                {list?.map((seller, index) => {
                  return (
                    <li key={seller?.value} className='d-flex align-items-center'> 
                    <div className='d-flex align-items-center gap-10'>
                        <CheckBox id={seller?.value} classes="check-lg" checked={selectedList?.indexOf(seller.value) > -1} action={(event) => updateSelectedItems(event, level, seller)} />
                        <div className='d-flex flex-column'>
                          <h2 className='f-12 f-w-500 d-flex align-items-center gap-4 f-101828'>
                            {seller.label}
                            {/* <span className='f-0A60CB'>({seller.profile})</span> */}
                          </h2>
                          <span className='f-12 f-w-500 f-10182880 l-20 pt-2'>{seller.value}</span>
                        </div>
                    </div>
                </li>
                  )
                })}
                {list?.length == 0 ? <li className="text-dark f-10 f-w-500 hei-70 d-flex align-items-center justify-content-center no-wrap">No Vendor Account found</li> : null}
            </ul>
        </React.Fragment>
  )
}
